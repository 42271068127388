<template>

    <div id="anamnese" class="card mt-2rem" >
        <div class="card-header" >
            <h4 class="ft-1rem mb-0" >Questionário de Anamnese</h4>
        </div>
        <div class="card-body" >
            <div v-for="(item, index) in anamnese" :key="item['ID_QST_ITEM']" >
                <div v-if="item['ID_TIPO_RSP'] == 1" >
                    <div class="form-group col-12" >
                        <label :for="item['ID_QST_ITEM']" >{{ item['TX_PERGUNTA'] }}</label>
                        <select class="form-control" type="text" required
                            :id="item['ID_QST_ITEM']"
                            v-model="anamnese[index]['TX_RESPOSTA']"
                        >
                            <option value="" disabled >Selecione uma opção</option>
                            <option value="Sim" >Sim</option>
                            <option value="Não" >Não</option>
                        </select>
                    </div>
                </div>
                <div v-else-if="item['ID_TIPO_RSP'] == 2" >
                    <div class="form-group col-12" >
                        <label :for="item['ID_QST_ITEM']" >{{ item['TX_PERGUNTA'] }}</label>
                        <select class="form-control" type="text" required
                            :id="item['ID_QST_ITEM']"
                            v-model="anamnese[index]['TX_RESPOSTA']"
                            @change="checkSelectedOpt($event)"
                        >
                            <option value="" disabled >Selecione uma opção</option>
                            <option value="Sim" data-test="true"  >Sim</option>
                            <option value="Não" data-test="false" >Não</option>
                        </select>
                        <div class="d-none mt-2" >
                            <textarea v-model="anamnese[index]['TX_RESPOSTA_1']" class="form-control" rows="2" placeholder="Descreva" ></textarea>
                        </div>
                    </div>
                </div>
                <div v-else > <!-- ID_TIPO_RSP == 3 -->
                    <div class="form-group col-12" >
                        <label :for="item['ID_QST_ITEM']" >{{ item['TX_PERGUNTA'] }}</label>
                        <textarea v-model="anamnese[index]['TX_RESPOSTA']" class="form-control" rows="2" :id="item['ID_QST_ITEM']" required ></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        props:
        {
            anamnese: [],
        },

        methods:
        {
            checkSelectedOpt( event )
            {
                let opt    = event.target.value;
                let sblDiv = event.target.nextElementSibling;

                // essa será a resposta que dirá
                // se é pra exibir o campo de texto ou esconder
                // console.log( event.target.selectedOptions[ 0 ].dataset.test );

                if ( opt == "Sim" )
                {
                    sblDiv.classList.remove( "d-none" );
                    sblDiv.children[ 0 ].setAttribute( "required", "required" );
                }
                else
                {
                    sblDiv.classList.add( "d-none" );
                    sblDiv.children[ 0 ].removeAttribute( "required" );
                }
            },
        }
    }
</script>

<style scoped ></style>
