require('./bootstrap');

import Vue from 'vue';

import VueTheMask from 'vue-the-mask';
Vue.use( VueTheMask );

import VueClipboard from 'vue-clipboard2';
Vue.use( VueClipboard );

import VueSignaturePad from 'vue-signature-pad';
Vue.use( VueSignaturePad );

import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use( BootstrapVue );

import router from './router/routes';

Vue.component('easy-dental', require('./components/EasyDental.vue').default);

const app = new Vue({
    el: '#app',
	router,
});
