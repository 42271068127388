<template>

    <div class="container-fluid" v-if="token == false" >
        <div class="row justify-content-center" >
            <div class="col-12 col-md-8" >

                <div class="card mt-2rem" >
                    <div class="card-header" >
                        <h4 class="ft-1rem mb-0" >Gerar link de contrato</h4>
                    </div>

                    <div class="card-body" >
                        <div class="form-group row mb-0" >
                            <label for="cpf" class="col-md-4 col-form-label text-md-right" >CPF</label>
                            <div class="col-md-6" >
                                <input id="cpf" class="form-control" type="text" name="cpf" placeholder="ex: 000.000.000-00" v-model="scpf" v-mask="'###.###.###-##'" v-on:change="recuperarPaciente" required autofocus >
                            </div>
                        </div>

                        <div class="form-group row mb-0 mt-3" >
                            <label for="nprest" class="col-md-4 col-form-label text-md-right" >Dentista</label>
                            <div class="col-md-6" >
                                <select v-model="nprest" id="nprest" class="form-control" type="text" v-bind:disabled="listProvs == ''" v-on:change="selecionaPrestador($event)" required >
                                    <option v-for="item in listProvs" v-bind:key="item.ID_PRESTADOR" v-bind:value="item.ID_PRESTADOR" >
                                        {{ item.NM_PRESTADOR }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row mb-0 mt-3" >
                            <label for="sdoc" class="col-md-4 col-form-label text-md-right" >Documento</label>
                            <div class="col-md-6" >
                                <select v-model="sdoc" id="sdoc" class="form-control" type="text" v-bind:disabled="listDocs == ''" v-on:change="selecionaDocumento($event)" required >
                                    <option v-for="item in listDocs" v-bind:key="item" v-bind:value="item" >
                                        {{ item }}
                                    </option>
                                </select>
                            </div>
                        </div>


                        <div class="form-group row mb-0" >
                            <div class="col-md-6 offset-md-4" v-if="generatingLink" >
                                <div class="alert alert-info mb-0 mt-3" >
                                    <div class="d-flex align-items-center" >
                                        <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                            <span class="sr-only" >Loading...</span>
                                        </div>
                                        <span>Consultando cadastro e gerando link...</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 offset-md-4" v-if="existingCPF == false || existingSel == false" key="fail" >
                                <div class="alert alert-warning mb-0 mt-3" >
                                    <i class="fas fa-exclamation-circle" ></i> {{ this.message }}
                                </div>
                                <button class="btn btn-primary btn-block mx-auto mt-3" v-on:click="resetProps" >Limpar</button>
                            </div>

                            <div class="col-md-6 offset-md-4" v-if="existingCPF == true && link != ''" key="success" >
                                <div class="alert alert-success mb-0 mt-3" >
                                    <i class="fas fa-check-circle" ></i> Tudo certo! Clique em Copiar para copiar o link.
                                </div>
                                <button class="btn btn-secondary btn-block mx-auto mt-3" v-on:click="resetProps" >Gerar novo link</button>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer" v-if="!generatingLink && link != ''" >
                        <div class="row mb-0" >
                            <div class="col-12 col-md-8 col-lg-9 pr-md-2" >
                                <textarea class="form-control" name="link" rows="7" v-model="link" disabled ></textarea>
                            </div>
                            <div class="col-12 col-md-4 col-lg-3 pl-md-2 mt-3 mt-md-0" >
                                <button class="btn btn-primary btn-block" v-clipboard:copy="link" >Copiar</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>

</template>

<script>
    import axios from "axios";

    export default {
        data()
        {
            return {
                headers        : { headers : { "Content-Type" : "application/json", Accept : "*" } },

                generatingLink : false,
                existingCPF    : null,
                existingSel    : null,
                message        : "",

                link           : "",
                scpf           : "",
                nprest         : "",
                sdoc           : "",
                listProvs      : [],
                listDocs       : [],

                countPrest: 0,
                countDocs:  0,
            };
        },
        props:
        {
            url: { type: String, required: true },
            token: { type: Boolean, default: false },
            expAccess: { type: String, required: false },
            expDocument: { type: String, required: false },
			clinic: Object,
        },

        beforeMount()
        {
            if ( this.token )
                window.location.href = "nao_autorizado";
        },

        methods:
        {
            carregarPrestadores()
            {
                this.countPrest += 1;

                axios
                .get( `${this.url}/clinica/prestadores` )
                .then( response =>
                {
                    this.listProvs = response.data;
                } )
                .catch( err =>
                {
                    console.log( `retrieveProviders(), ${err.name}: ${err.message}` );

                    if ( this.countPrest <= 3 )
                    {
                        this.carregarPrestadores();
                    }
                    else
                    {
                        this.existingSel = false;
                        this.message     = "Ops! Ocorreu um erro na leitura dos dados. Por favor retorne e tente novamente.";
                    }
                } );
            },
            carregarDocumentos()
            {
                this.countDocs += 1;

                axios
                .get( `${this.url}/clinica/listadocumentos` )
                .then( response =>
                {
                    this.listDocs = response.data;
                })
                .catch( err =>
                {
                    console.log( `retrieveDocsList(), ${err.name}: ${err.message}` );

                    if ( this.countDocs <= 3 )
                    {
                        this.carregarDocumentos();
                    }
                    else
                    {
                        this.existingSel = false;
                        this.message     = "Ops! Ocorreu um erro na leitura dos dados. Por favor retorne e tente novamente.";
                    }
                } );
            },

            selecionaPrestador( e )
            {
                this.nprest = e.target.value;

                if ( this.existingCPF == true && this.sdoc != "" )
                    this.generateLink();
            },
            selecionaDocumento( e )
            {
                this.sdoc = e.target.value;

                if ( this.existingCPF == true && this.nprest != "" )
                    this.generateLink();
            },

            recuperarPaciente()
            {
                let treatedCPF = this.scpf.replace( /\D/g, "" );

                if ( this.scpf.length == 14 )
                {
                    axios
                    .get( `${this.url}/clinica/paciente/${treatedCPF}` )
                    .then( response =>
                    {
                        let data = response.data;

                        if ( data.nropac &&
                            !( data.sanot.length   === 0 ) &&
                            !( data.nconv.length   === 0 ) &&
                            !( data.ntpind.length  === 0 ) &&
                            !( data.nestciv.length === 0 ) )
                        {
                            this.existingCPF = true;

                            if ( this.nprest != "" && this.sdoc != "" )
                                this.generateLink();
                        }
                        else
                        {
                            this.existingCPF = false;
                            this.message     = "CPF não encontrado.";
                        }
                    } )
                    .catch( err =>
                    {
                        console.log( `retrievePatient(), ${err.name}: ${err.message}` );

                        this.existingCPF = false;
                        this.message     = "CPF não encontrado.";
                    } );
                }
            },

            generateLink()
            {
                this.generatingLink = true;

                axios
                .post(
                    `${this.url}/gerar/link/documento`,
                    {
                        scpf    : this.scpf,
                        nprest  : this.nprest,
                        sdoc    : this.sdoc,
                    },
                    this.headers
                )
                .then(response =>
                {
                    this.link  = this.clinic.nome;
                    this.link += "\n\nClique no link abaixo para acessar o documento.";
                    this.link += "\n*Atenção! O link é válido por " + this.expAccess + " horas após o primeiro acesso, e validade máxima de " + this.expDocument + " horas.*\n\n";
                    this.link += response.data.link;
                })
                .catch( err =>
                {
                    console.log( `linkDocument(), ${err.name}: ${err.message}` );

                    this.link = "";
                } );

                this.generatingLink = false;
            },

            resetProps()
            {
                // window.location.href = 'gerar-link-paciente';
                this.existingCPF = null;
                this.existingSel = null;
                this.message     = "";
                this.link        = "";
                this.scpf        = "";
                this.nprest      = "";
                this.sdoc        = "";
                this.countPrest  = 0;
                this.countDocs   = 0;
            },
        },

        mounted()
        {
            axios
            .post(
                `${this.url}/easy-log`,
                {
                    route : this.$route.path,
                    code  : 200,
                },
            );

            this.carregarPrestadores();
            this.carregarDocumentos();
        },
    }
</script>

<style scoped >
</style>
