<template>

    <div class="container" >
        <div class="row justify-content-center" >
            <div class="col-12" >
                <div class="card mt-2rem" >
                    <div class="card-body" >
                        <h4 class="ft-1rem mb-5" >Dashboard</h4>

                        <form @submit.prevent="submitForm" id="formChart" >
                            <div class="row" >
                                <div class="form-group col-12 col-sm-6 col-md-3" >
                                    <label for="sano1" >Ano principal:</label>
                                    <select v-model="sano1" id="sano1" class="form-control" type="text" :disabled="listaAno == ''" required >
                                        <option value="" disabled >Selecione uma opção</option>
                                        <option v-for="item in listaAno" :key="item" :value="item" >
                                            {{ item }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-12 col-sm-6 col-md-3" >
                                    <label for="sano2" >Ano secundário:</label>
                                    <select v-model="sano2" id="sano2" class="form-control" type="text" :disabled="listaAno == ''" >
                                        <option value="" disabled >Selecione uma opção</option>
                                        <option value="0" >Nenhum</option>
                                        <option v-for="item in listaAno" :key="item" :value="item" >
                                            {{ item }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-12 col-sm-6 col-md-3" >
                                    <label for="nprest" >Prestador:</label>
                                    <select v-model="nprest" id="nprest" class="form-control" type="text" :disabled="listaPrest == ''" required >
                                        <option value="" disabled >Selecione uma opção</option>
                                        <option value="0" >Todos</option>
                                        <option v-for="item in listaPrest" :key="item.ID_PRESTADOR" :value="item.ID_PRESTADOR" >
                                            {{ item.NM_PRESTADOR }}
                                        </option>
                                    </select>
                                </div>

                                <div class="form-group col-12 col-sm-6 col-md-3" >
                                    <label for="sunid" >Unidade:</label>
                                    <select v-model="sunid" id="sunid" class="form-control" type="text" :disabled="listaUnid == ''" required >
                                        <option value="" disabled >Selecione uma opção</option>
                                        <option value="0" >Todas</option>
                                        <option v-for="item in listaUnid" :key="item.ID_UNIDADE" :value="item.ID_UNIDADE" >
                                            {{ item.NM_UNIDADE }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="row" >
                                <div class="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto" >
                                    <button type="submit" class="btn btn-success btn-block"
                                        :disabled="(
                                            this.carregadoVenda    === null ||
                                            this.carregadoReceita  === null ||
                                            this.carregadoPaciente === null ||
                                            this.carregadoIndicNP  === null ||
                                            this.carregadoIndicOS  === null ||
                                            this.carregadoIndicOM  === null ||
                                            this.carregadoIndicOA  === null
                                        )" >
                                        Gerar gráficos
                                    </button>
                                </div>
                            </div>
                            <div class="row"
                                v-if="(
                                    this.carregadoVenda    === null ||
                                    this.carregadoReceita  === null ||
                                    this.carregadoPaciente === null ||
                                    this.carregadoIndicNP  === null ||
                                    this.carregadoIndicOS  === null ||
                                    this.carregadoIndicOM  === null ||
                                    this.carregadoIndicOA  === null
                                )" >
                                <div class="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto" >
                                    <div class="alert alert-info mt-3 mb-0" >
                                        <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                            <span class="sr-only" >Loading...</span>
                                        </div>
                                        Buscando dados...
                                    </div>
                                </div>
                            </div>

                            <div class="row" >
                                <div class="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto" >
                                    <div class="alert alert-warning mt-3 mb-0"
                                        v-if="(
                                            this.falhaVenda    == true ||
                                            this.falhaReceita  == true ||
                                            this.falhaPaciente == true ||
                                            this.falhaIndicNP  == true ||
                                            this.falhaIndicOS  == true ||
                                            this.falhaIndicOM  == true ||
                                            this.falhaIndicOA  == true ||
                                            this.falhaDados    == true
                                        )" >
                                        <i class="fas fa-exclamation-circle" ></i> Ops! Alguns dados não puderam ser carregados. Por favor recarregue a página.
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div v-if="mostrarGraficos" >

                            <div class="row" >
                                <!-- Dashboard Receita - DshAnualReceitas -->
                                <div class="col-12 col-md-6" >
                                    <div class="mt-5" >
                                        <h4 class="mt-3 text-center" >Receita Prevista x Realizada {{ this.cReceitaAno }}</h4>
                                        <grid-loader class="mx-auto" color="#37d5f2"               v-if="carregadoReceita == null" ></grid-loader>
                                        <line-chart :chart-data="chartReceita" :options="chartOpt" v-if="carregadoReceita == true" />
                                        <div class="mx-auto text-center"                           v-if="carregadoReceita == false" >
                                            <i class="fas fa-5x fa-times text-danger mx-auto" ></i>
                                        </div>
                                    </div>
                                </div>

                                <!-- Dashboard Vendas - DshAnualVendas -->
                                <div class="col-12 col-md-6" >
                                    <div class="mt-5" >
                                        <h4 class="mt-3 text-center" >Vendas Realizadas</h4>
                                        <grid-loader class="mx-auto" color="#37d5f2"             v-if="carregadoVenda == null" ></grid-loader>
                                        <line-chart :chart-data="chartVenda" :options="chartOpt" v-if="carregadoVenda == true" />
                                        <div class="mx-auto text-center"                         v-if="carregadoVenda == false" >
                                            <i class="fas fa-5x fa-times text-danger mx-auto" ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Dashboard Tipo Indicação NP - DshTpIndNP -->
                            <div class="row" >
                                <div class="col-12 d-none d-md-block" >
                                    <h4 class="mt-5 text-center" >Novos Pacientes</h4>
                                </div>
                                <div class="col-12 col-md-4" >
                                    <h4 class="mt-5 text-center d-md-none" >Novos Pacientes</h4>
                                    <h5 class="text-center" >{{ this.tituloNvPac1 }}</h5>
                                    <grid-loader class="mx-auto" color="#37d5f2"                       v-if="carregadoIndicNP == null" ></grid-loader>
                                    <doughnut-chart :chart-data="chartNvPac1" :options="chartDoughOpt" v-if="carregadoIndicNP == true" />
                                    <div class="mx-auto text-center"                                   v-if="carregadoIndicNP == false" >
                                        <i class="fas fa-5x fa-times text-danger mx-auto" ></i>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4" >
                                    <h4 class="mt-5 text-center d-md-none" >Novos Pacientes</h4>
                                    <h5 class="text-center" >{{ this.tituloNvPac2 }}</h5>
                                    <grid-loader class="mx-auto" color="#37d5f2"                       v-if="carregadoIndicNP == null" ></grid-loader>
                                    <doughnut-chart :chart-data="chartNvPac2" :options="chartDoughOpt" v-if="carregadoIndicNP == true" />
                                    <div class="mx-auto text-center"                                   v-if="carregadoIndicNP == false" >
                                        <i class="fas fa-5x fa-times text-danger mx-auto" ></i>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4" >
                                    <h4 class="mt-5 text-center d-md-none" >Novos Pacientes</h4>
                                    <h5 class="text-center" >{{ this.tituloNvPac3 }}</h5>
                                    <grid-loader class="mx-auto" color="#37d5f2"                       v-if="carregadoIndicNP == null" ></grid-loader>
                                    <doughnut-chart :chart-data="chartNvPac3" :options="chartDoughOpt" v-if="carregadoIndicNP == true" />
                                    <div class="mx-auto text-center"                                   v-if="carregadoIndicNP == false" >
                                        <i class="fas fa-5x fa-times text-danger mx-auto" ></i>
                                    </div>
                                </div>
                            </div>

                            <div class="row" >
                                <div class="col-12 d-none d-md-block" >
                                    <h4 class="mt-5 text-center" >Orçamentos Aprovados</h4>
                                </div>

                                <!-- Dashboard Tipo Indicação OS - DshTpIndOS -->
                                <div class="col-12 col-md-4" >
                                    <h4 class="mt-5 text-center d-md-none" >Orçamentos Aprovados</h4>
                                    <h4 class="text-center" >{{ this.tituloIndicOS }}</h4>
                                    <grid-loader class="mx-auto" color="#37d5f2"                        v-if="carregadoIndicOS == null" ></grid-loader>
                                    <doughnut-chart :chart-data="chartIndicOS" :options="chartDoughOpt" v-if="carregadoIndicOS == true" />
                                    <div class="mx-auto text-center"                                    v-if="carregadoIndicOS == false" >
                                        <i class="fas fa-5x fa-times text-danger mx-auto" ></i>
                                    </div>
                                </div>

                                <!-- Dashboard Tipo Indicação OM - DshTpIndOM -->
                                <div class="col-12 col-md-4" >
                                    <h4 class="mt-5 text-center d-md-none" >Orçamentos Aprovados</h4>
                                    <h4 class="text-center" >{{ this.tituloIndicOM }}</h4>
                                    <grid-loader class="mx-auto" color="#37d5f2"                        v-if="carregadoIndicOM == null" ></grid-loader>
                                    <doughnut-chart :chart-data="chartIndicOM" :options="chartDoughOpt" v-if="carregadoIndicOM == true" />
                                    <div class="mx-auto text-center"                                    v-if="carregadoIndicOM == false" >
                                        <i class="fas fa-5x fa-times text-danger mx-auto" ></i>
                                    </div>
                                </div>

                                <!-- Dashboard Tipo Indicação OA - DshTpIndOA -->
                                <div class="col-12 col-md-4" >
                                    <h4 class="mt-5 text-center d-md-none" >Orçamentos Aprovados</h4>
                                    <h4 class="text-center" >{{ this.tituloIndicOA }}</h4>
                                    <grid-loader class="mx-auto" color="#37d5f2"                        v-if="carregadoIndicOA == null" ></grid-loader>
                                    <doughnut-chart :chart-data="chartIndicOA" :options="chartDoughOpt" v-if="carregadoIndicOA == true" />
                                    <div class="mx-auto text-center"                                    v-if="carregadoIndicOA == false" >
                                        <i class="fas fa-5x fa-times text-danger mx-auto" ></i>
                                    </div>
                                </div>
                            </div>

                            <!-- Dashboard Paciente - DshPct-->
                            <div class="row" >
                                <div class="col-12 col-md-4" >
                                    <h4 class="mt-5 mb-n2 mb-md-n3 text-center" >{{ this.tituloPacts1 }}</h4>
                                    <grid-loader class="mt-3 mx-auto" color="#37d5f2"                   v-if="carregadoPaciente == null" ></grid-loader>
                                    <doughnut-chart :chart-data="chartPacts1" :options="chartPactsOpt1" v-if="carregadoPaciente == true" />
                                    <div class="mx-auto text-center"                                    v-if="carregadoPaciente == false" >
                                        <i class="fas fa-5x fa-times text-danger mx-auto" ></i>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4" >
                                    <h4 class="mt-5 mb-n2 mb-md-n3 text-center" >{{ this.tituloPacts2 }}</h4>
                                    <grid-loader class="mt-3 mx-auto" color="#37d5f2"                   v-if="carregadoPaciente == null" ></grid-loader>
                                    <doughnut-chart :chart-data="chartPacts2" :options="chartPactsOpt2" v-if="carregadoPaciente == true" />
                                    <div class="mx-auto text-center"                                    v-if="carregadoPaciente == false" >
                                        <i class="fas fa-5x fa-times text-danger mx-auto" ></i>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4" >
                                    <h4 class="mt-5 mb-n2 mb-md-n3 text-center" >{{ this.tituloPacts3 }}</h4>
                                    <grid-loader class="mt-3 mx-auto" color="#37d5f2"                   v-if="carregadoPaciente == null" ></grid-loader>
                                    <doughnut-chart :chart-data="chartPacts3" :options="chartPactsOpt3" v-if="carregadoPaciente == true" />
                                    <div class="mx-auto text-center"                                    v-if="carregadoPaciente == false" >
                                        <i class="fas fa-5x fa-times text-danger mx-auto" ></i>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import axios          from "axios";
    import randomColor    from 'randomcolor';
    import LineChart      from "../charts/LineChart.vue";
    import DoughnutChart  from "../charts/DoughnutChart.vue";
    import { GridLoader } from 'vue-spinner/dist/vue-spinner.min.js';

    export default {
        components:
        {
            LineChart,
            DoughnutChart,
            GridLoader
        },

        props:
        {
            url: { type: String, required: true },
        },

        data()
        {
            return {
                headers      : { headers : { "Content-Type" : "application/json", Accept : "*" } },

                cReceitaAno  : "",
                chartReceita : null,
                chartVenda   : null,
                chartOpt     : {
                    scales : { y : { beginAtZero : true } },
                },

                tituloNvPac1      : "",
                tituloNvPac2      : "",
                tituloNvPac3      : "",
                chartNvPac1       : null,
                chartNvPac2       : null,
                chartNvPac3       : null,

                chartDoughOpt     : {
                    responsive          : true,
                    maintainAspectRatio : false,
                    animation           : {
                        animateRotate : false
                    }
                },

                chartPactsOpt1    : {
                    responsive          : true,
                    maintainAspectRatio : false,
                    animation           : { animateRotate : false },
                    tooltips            : { enabled       : false },
                    elements            : { center        : { text : "" } }
                },
                chartPactsOpt2    : {
                    responsive          : true,
                    maintainAspectRatio : false,
                    animation           : { animateRotate : false },
                    tooltips            : { enabled       : false },
                    elements            : { center        : { text : "" } }
                },
                chartPactsOpt3    : {
                    responsive          : true,
                    maintainAspectRatio : false,
                    animation           : { animateRotate : false },
                    tooltips            : { enabled       : false },
                    elements            : { center        : { text : "" } }
                },

                tituloIndicOS     : "",
                tituloIndicOM     : "",
                tituloIndicOA     : "",
                chartIndicOS      : null,
                chartIndicOM      : null,
                chartIndicOA      : null,

                tituloPacts1      : "",
                tituloPacts2      : "",
                tituloPacts3      : "",
                chartPacts1       : null,
                chartPacts2       : null,
                chartPacts3       : null,

                carregadoVenda    : "",
                carregadoReceita  : "",
                carregadoPaciente : "",
                carregadoIndicNP  : "",
                carregadoIndicOS  : "",
                carregadoIndicOM  : "",
                carregadoIndicOA  : "",

                falhaVenda        : null,
                falhaReceita      : null,
                falhaPaciente     : null,
                falhaIndicNP      : null,
                falhaIndicOS      : null,
                falhaIndicOM      : null,
                falhaIndicOA      : null,

                mostrarGraficos   : false,

                sano1             : "",
                sano2             : "",
                nprest            : "",
                sunid             : "",

                countPrest        : 0,
                countUnid         : 0,

                listaPrest        : [],
                listaUnid         : [],
                listaAno          : [],

                falhaDados        : null,
            }
        },

        methods:
        {
            async submitForm()
            {
                this.sano1  = parseInt( this.sano1  );
                this.sano2  = parseInt( this.sano2  );
                this.nprest = parseInt( this.nprest );
                this.sunid  = parseInt( this.sunid  );

                this.carregadoVenda    = null;
                this.carregadoReceita  = null;
                this.carregadoPaciente = null;
                this.carregadoIndicNP  = null;
                this.carregadoIndicOS  = null;
                this.carregadoIndicOM  = null;
                this.carregadoIndicOA  = null;

                this.falhaVenda        = null;
                this.falhaReceita      = null;
                this.falhaPaciente     = null;
                this.falhaIndicNP      = null;
                this.falhaIndicOS      = null;
                this.falhaIndicOM      = null;
                this.falhaIndicOA      = null;

                this.falhaDados        = false;
                this.mostrarGraficos   = true;

                await this.carregaGraficoReceitas();
                await this.carregaGraficoVendas();

                await this.carregaGraficoIndicacaoNP();

                await this.carregaGraficoIndicacaoOS();
                await this.carregaGraficoIndicacaoOM();
                await this.carregaGraficoIndicacaoOA();

                await this.carregaGraficoPacientes();
            },

            carregarAnos()
            {
                const now     = new Date().getUTCFullYear();
                this.listaAno = Array( now - ( now - 10 ) ).fill( '' ).map( ( v, idx ) => now - idx );
            },
            carregarPrestadores()
            {
                this.countPrest += 1;

                axios
                .get( `${this.url}/clinica/prestadores` )
                .then( response =>
                {
                    this.listaPrest = ( !Array.isArray( response.data ) ) ? [ response.data ] : response.data;
                    this.countPrest = 0;
                } )
                .catch( err =>
                {
                    console.log( `retrieveProviders(), ${err.name}: ${err.message}` );

                    if ( this.countPrest <= 3 )
                        this.carregarPrestadores();
                    else
                        this.falhaDados = true;
                } );
            },
            carregarUnidades()
            {
                this.countUnid += 1;

                axios
                .get( `${this.url}/clinica/unidades` )
                .then( response =>
                {
                    this.listaUnid = ( !Array.isArray( response.data ) ) ? [ response.data ] : response.data;
                    this.countUnid = 0;
                } )
                .catch( err =>
                {
                    console.log( `retrieveUnits(), ${err.name}: ${err.message}` );

                    if ( this.countDocs <= 3 )
                        this.carregarDocumentos();
                    else
                        this.falhaDados = true;
                } );
            },

            // GRAFICO VENDAS
            async carregaGraficoVendas()
            {
                axios
                .post(
                    `${this.url}/clinica/graficos/vendas`,
                    {
                        sano1   : this.sano1,
                        sano2   : this.sano2 || this.sano1,
                        nprest  : this.nprest,
                        sunid   : this.sunid,
                    },
                    this.headers
                ).
                then( response =>
                {
                    if ( response.status === 200 )
                    {
                        this.trataGraficoVendas( response.data );
                        this.carregadoVenda = true;
                    }
                    else
                    {
                        this.carregadoVenda = false;
                    }
                } )
                .catch( err =>
                {
                    console.log( `retrieveAnnualSales(), ${err.name}: ${err.message}` );

                    this.carregadoVenda = false;
                    this.falhaVenda     = true;
                } );
            },
            trataGraficoVendas( data )
            {
                this.chartVenda       = null;

                let graficos          = data;
                let tempLabelsVenda   = [];
                let tempSetsVenda     = [];

                graficos.forEach( ( e, i ) =>
                {
                    let grafico      = e.data;
                    let auxDataSet   = {};

                    auxDataSet.label = e.ano;
                    auxDataSet.data  = [];

                    Object.entries( grafico ).forEach( ( [ key, value ] ) =>
                    {
                        if ( !tempLabelsVenda.includes( key ) )
                            tempLabelsVenda.push( key );

                        auxDataSet.data.push( this.formataValorFloat( value ) );
                    } );

                    if ( e.ano == this.sano1 )
                    {
                        auxDataSet.backgroundColor = [ 'rgba(103, 78, 167,   0)' ];
                        auxDataSet.borderColor     = [ 'rgba(103, 78, 167, .75)' ];
                    }
                    else
                    {
                        auxDataSet.backgroundColor = [ 'rgba(241, 194, 50,   0)' ];
                        auxDataSet.borderColor     = [ 'rgba(241, 194, 50, .75)' ];
                    }

                    auxDataSet.borderWidth = 2;
                    tempSetsVenda.push( auxDataSet );
                } );

                this.chartVenda = {
                    labels   : tempLabelsVenda,
                    datasets : tempSetsVenda
                }
            },

            // GRAFICO RECEITAS
            async carregaGraficoReceitas()
            {
                axios
                .post(
                    `${this.url}/clinica/graficos/receitas`,
                    {
                        sano1   : this.sano1,
                        sano2   : this.sano2 || this.sano1,
                        nprest  : this.nprest,
                        sunid   : this.sunid,
                    },
                    this.headers
                ).
                then( response =>
                {
                    if ( response.status === 200 )
                    {
                        this.trataGraficoReceitas( response.data );
                        this.carregadoReceita = true;
                    }
                    else
                    {
                        this.carregadoReceita = false;
                    }
                } )
                .catch( err =>
                {
                    console.log( `retrieveAnnualRecipes(), ${err.name}: ${err.message}` );

                    this.carregadoReceita = false;
                    this.falhaReceita     = true;
                } );
            },
            trataGraficoReceitas( data )
            {
                this.cReceitaAno      = "";
                this.chartReceita     = null;

                let graficos          = data;

                let tempLabelsReceita = [];
                let tempSetsReceita   = [];

                graficos.forEach( ( e, i ) =>
                {
                    let grafico      = e.data;
                    let auxDataSet   = {};

                    auxDataSet.label = e.titulo;
                    auxDataSet.data  = [];

                    Object.entries( grafico ).forEach( ( [ key, value ] ) =>
                    {
                        if ( !tempLabelsReceita.includes( key ) )
                            tempLabelsReceita.push( key );

                        auxDataSet.data.push( this.formataValorFloat( value ) );
                    } );

                    if ( e.titulo.includes( "Prevista" ) )
                    {
                        auxDataSet.backgroundColor = [ 'rgba(255, 159, 64,   0)' ];
                        auxDataSet.borderColor     = [ 'rgba(255, 159, 64, .75)' ];
                    }
                    else
                    {
                        auxDataSet.backgroundColor = [ 'rgba(56, 118, 29,   0)' ];
                        auxDataSet.borderColor     = [ 'rgba(56, 118, 29, .75)' ];
                    }

                    auxDataSet.borderWidth = 2;
                    tempSetsReceita.push( auxDataSet );
                } );

                this.cReceitaAno  = " - " + this.sano1;
                this.chartReceita = {
                    labels   : tempLabelsReceita,
                    datasets : tempSetsReceita
                }
            },

            // GRAFICO PACIENTES (faltas/inadimplecia)
            async carregaGraficoPacientes()
            {
                axios
                .post(
                    `${this.url}/clinica/graficos/pacientes`,
                    {
                        sano1   : this.sano1,
                        sano2   : this.sano2 || this.sano1,
                        nprest  : this.nprest,
                        sunid   : this.sunid,
                    },
                    this.headers
                ).
                then( response =>
                {
                    if ( response.status === 200 )
                    {
                        this.trataGraficoPacientes( response.data );
                        this.carregadoPaciente = true;
                    }
                    else
                    {
                        this.carregadoPaciente = false;
                    }
                } )
                .catch( err =>
                {
                    console.log( `retrieveGraphPatients(), ${err.name}: ${err.message}` );

                    this.carregadoPaciente = false;
                    this.falhaPaciente     = true;
                } );
            },
            trataGraficoPacientes( data )
            {
                this.resetGraficoPacientes();

                let graficos = data;

                graficos.forEach(( e, i ) =>
                {
                    if ( i + 1 == 1 )
                    {
                        this.tituloPacts1 = e.titulo;
                        this.chartPactsOpt1.elements.center.text = e.data[ 0 ] + "%";
                        this.chartPacts1  = {
                            datasets: [ {
                                label: "",
                                data: [ e.data[ 0 ], e.data[ 1 ] ],
                                backgroundColor: [ "#399" ]
                            } ]
                        };
                    }
                    else if ( i + 1 == 2 )
                    {
                        this.tituloPacts2 = e.titulo;
                        this.chartPactsOpt2.elements.center.text = e.data[ 0 ] + "%";
                        this.chartPacts2  = {
                            datasets: [ {
                                label: "",
                                data: [ e.data[ 0 ], e.data[ 1 ] ],
                                backgroundColor: [ "#399" ]
                            } ]
                        };
                    }
                    else
                    {
                        this.tituloPacts3 = e.titulo;
                        this.chartPactsOpt3.elements.center.text = e.data[ 0 ] + "%";
                        this.chartPacts3  = {
                            datasets: [ {
                                label: "",
                                data: [ e.data[ 0 ], e.data[ 1 ] ],
                                backgroundColor: [ "#399" ]
                            } ]
                        };
                    }
                } );
            },
            resetGraficoPacientes()
            {
                this.tituloPacts1 = "";
                this.tituloPacts2 = "";
                this.tituloPacts3 = "";
                this.chartPacts1  = { datasets: [ { label: "", data: [], backgroundColor: [ "#399" ] } ] };
                this.chartPacts2  = { datasets: [ { label: "", data: [], backgroundColor: [ "#399" ] } ] };
                this.chartPacts3  = { datasets: [ { label: "", data: [], backgroundColor: [ "#399" ] } ] };

                this.chartPactsOpt1.elements.center.text = "";
                this.chartPactsOpt2.elements.center.text = "";
                this.chartPactsOpt3.elements.center.text = "";
            },

            // GRAFICO INDIC NOVOS PACIENTES (semanal/mensal/anual)
            async carregaGraficoIndicacaoNP()
            {
                axios
                .post(
                    `${this.url}/clinica/graficos/indicacao/np`,
                    {
                        sano1   : this.sano1,
                        sano2   : this.sano2 || this.sano1,
                        nprest  : this.nprest,
                        sunid   : this.sunid,
                    },
                    this.headers
                ).
                then( response =>
                {
                    if ( response.status === 200 )
                    {
                        this.trataGraficoIndicacaoNP( response.data );
                        this.carregadoIndicNP = true;
                    }
                    else
                    {
                        this.carregadoIndicNP = false;
                    }
                } )
                .catch( err =>
                {
                    console.log( `retrieveNewPatientGraphics(), ${err.name}: ${err.message}` );

                    this.carregadoIndicNP = false;
                    this.falhaIndicNP     = true;
                } );
            },
            trataGraficoIndicacaoNP( data )
            {
                this.resetGraficoIndicacaoNP();

                let color = [];

                // ANUAL
                if ( data.anual.length == 0 )
                {
                    this.tituloNvPac3 = "Anual";

                    this.chartNvPac3.labels.push( "Sem dados" );
                    this.chartNvPac3.datasets[ 0 ].data.push( 1 );
                    this.chartNvPac3.datasets[ 0 ].backgroundColor.push( "#f5f5f5" );
                }
                else
                {
                    color             = [];
                    this.tituloNvPac3 = data.anual[ 0 ].periodo_txt;

                    data.anual.forEach( el_a =>
                    {
                        let auxCr = randomColor();
                        while ( color.includes( auxCr ) ) auxCr = randomColor();

                        this.chartNvPac3.labels.push( this.formataIndicNenhum( el_a.tipo_indic ) );
                        this.chartNvPac3.datasets[ 0 ].data.push( el_a.valor );
                        this.chartNvPac3.datasets[ 0 ].backgroundColor.push( auxCr );
                    } );
                }

                // MENSAL
                if ( data.mensal.length == 0 )
                {
                    this.tituloNvPac2 = "Mensal";

                    this.chartNvPac2.labels.push( "Sem dados" );
                    this.chartNvPac2.datasets[ 0 ].data.push( 1 );
                    this.chartNvPac2.datasets[ 0 ].backgroundColor.push( "#f5f5f5" );
                }
                else
                {
                    color             = [];
                    this.tituloNvPac2 = data.mensal[ 0 ].periodo_txt;

                    data.mensal.forEach( el_m =>
                    {
                        let auxCr = randomColor();

                        while ( color.includes( auxCr ) ) auxCr = randomColor();

                        this.chartNvPac2.labels.push( this.formataIndicNenhum( el_m.tipo_indic ) );
                        this.chartNvPac2.datasets[ 0 ].data.push( el_m.valor );
                        this.chartNvPac2.datasets[ 0 ].backgroundColor.push( auxCr );
                    } );
                }

                // SEMANAL
                if ( data.semanal.length == 0 )
                {
                    this.tituloNvPac1 = "Semanal";

                    this.chartNvPac1.labels.push( "Sem dados" );
                    this.chartNvPac1.datasets[ 0 ].data.push( 1 );
                    this.chartNvPac1.datasets[ 0 ].backgroundColor.push( "#f5f5f5" );
                }
                else
                {
                    color             = [];
                    this.tituloNvPac1 = data.semanal[ 0 ].periodo_txt;

                    data.semanal.forEach( el_s =>
                    {
                        let auxCr = randomColor();

                        while ( color.includes( auxCr ) ) auxCr = randomColor();

                        this.chartNvPac1.labels.push( this.formataIndicNenhum( el_s.tipo_indic ) );
                        this.chartNvPac1.datasets[ 0 ].data.push( el_s.valor );
                        this.chartNvPac1.datasets[ 0 ].backgroundColor.push( auxCr );
                    } );
                }
            },
            resetGraficoIndicacaoNP()
            {
                this.tituloNvPac1 = "";
                this.tituloNvPac2 = "";
                this.tituloNvPac3 = "";
                this.chartNvPac1  = { labels: [], datasets: [ { label: "", data: [], backgroundColor: [] } ] };
                this.chartNvPac2  = { labels: [], datasets: [ { label: "", data: [], backgroundColor: [] } ] };
                this.chartNvPac3  = { labels: [], datasets: [ { label: "", data: [], backgroundColor: [] } ] };
            },

            // GRAFICO INDIC ORÇAMENTO SEMANAL
            async carregaGraficoIndicacaoOS()
            {
                axios
                .post(
                    `${this.url}/clinica/graficos/indicacao/os`,
                    {
                        sano1   : this.sano1,
                        sano2   : this.sano2 || this.sano1,
                        nprest  : this.nprest,
                        sunid   : this.sunid,
                    },
                    this.headers
                ).
                then( response =>
                {
                    if ( response.status === 200 )
                    {
                        this.trataGraficoIndicacaoOS( response.data );
                        this.carregadoIndicOS = true;
                    }
                    else
                    {
                        this.carregadoIndicOS = false;
                    }
                } )
                .catch( err =>
                {
                    console.log( `retrieveWeeklyBudgetGraphics(), ${err.name}: ${err.message}` );

                    this.carregadoIndicOS = false;
                    this.falhaIndicOS     = true;
                } );
            },
            trataGraficoIndicacaoOS( data )
            {
                this.resetGraficoIndicacaoOS();

                let color = [];

                if ( data.semanal.length == 0 )
                {
                    this.tituloIndicOS = "Semanal";

                    this.chartIndicOS.labels.push( "Sem dados" );
                    this.chartIndicOS.datasets[ 0 ].data.push( 1 );
                    this.chartIndicOS.datasets[ 0 ].backgroundColor.push( "#f5f5f5" );
                }
                else
                {
                    color              = [];
                    this.tituloIndicOS = data.semanal[ 0 ].periodo_txt;

                    data.semanal.forEach( el_s =>
                    {
                        let auxCr = randomColor();

                        while ( color.includes( auxCr ) ) auxCr = randomColor();

                        this.chartIndicOS.labels.push( this.formataIndicNenhum( el_s.tipo_indic ) );
                        this.chartIndicOS.datasets[ 0 ].data.push( this.formataValorFloat( el_s.valor ) );
                        this.chartIndicOS.datasets[ 0 ].backgroundColor.push( auxCr );
                    } );
                }
            },
            resetGraficoIndicacaoOS()
            {
                this.tituloIndicOS = "";
                this.chartIndicOS  = { labels: [], datasets: [ { label: "", data: [], backgroundColor: [] } ] };
            },

            // GRAFICO INDIC ORÇAMENTO MENSAL
            async carregaGraficoIndicacaoOM()
            {
                axios
                .post(
                    `${this.url}/clinica/graficos/indicacao/om`,
                    {
                        sano1   : this.sano1,
                        sano2   : this.sano2 || this.sano1,
                        nprest  : this.nprest,
                        sunid   : this.sunid,
                    },
                    this.headers
                ).
                then( response =>
                {
                    if ( response.status === 200 )
                    {
                        this.trataGraficoIndicacaoOM(response.data);
                        this.carregadoIndicOM = true;
                    }
                    else
                    {
                        this.carregadoIndicOM = false;
                    }
                } )
                .catch( err =>
                {
                    console.log( `retrieveMonthlyBudgetGraphics(), ${err.name}: ${err.message}` );

                    this.carregadoIndicOM = false;
                    this.falhaIndicOM     = true;
                } );
            },
            trataGraficoIndicacaoOM( data )
            {
                this.resetGraficoIndicacaoOM();

                let color = [];

                if ( data.mensal.length == 0 )
                {
                    this.tituloIndicOM = "Mensal";

                    this.chartIndicOM.labels.push( "Sem dados" );
                    this.chartIndicOM.datasets[ 0 ].data.push( 1 );
                    this.chartIndicOM.datasets[ 0 ].backgroundColor.push( "#f5f5f5" );
                }
                else
                {
                    color              = [];
                    this.tituloIndicOM = data.mensal[ 0 ].periodo_txt;

                    data.mensal.forEach( el_s =>
                    {
                        let auxCr = randomColor();

                        while ( color.includes( auxCr ) ) auxCr = randomColor();

                        this.chartIndicOM.labels.push( this.formataIndicNenhum( el_s.tipo_indic ) );
                        this.chartIndicOM.datasets[ 0 ].data.push( this.formataValorFloat( el_s.valor ) );
                        this.chartIndicOM.datasets[ 0 ].backgroundColor.push( auxCr );
                    } );
                }
            },
            resetGraficoIndicacaoOM()
            {
                this.tituloIndicOM = "";
                this.chartIndicOM  = { labels: [], datasets: [ { label: "", data: [], backgroundColor: [] } ] };
            },

            // GRAFICO INDIC ORÇAMENTO ANUAL
            async carregaGraficoIndicacaoOA()
            {
                axios
                .post(
                    `${this.url}/clinica/graficos/indicacao/oa`,
                    {
                        sano1   : this.sano1,
                        sano2   : this.sano2 || this.sano1,
                        nprest  : this.nprest,
                        sunid   : this.sunid,
                    },
                    this.headers
                ).
                then( response =>
                {
                    if ( response.status === 200 )
                    {
                        this.trataGraficoIndicacaoOA( response.data );
                        this.carregadoIndicOA = true;
                    }
                    else
                    {
                        this.carregadoIndicOA = false;
                    }
                } )
                .catch( err =>
                {
                    console.log( `retrieveAnnualBudgetGraphics(), ${err.name}: ${err.message}` );

                    this.carregadoIndicOA = false;
                    this.falhaIndicOA     = true;
                } );
            },
            trataGraficoIndicacaoOA( data )
            {
                this.resetGraficoIndicacaoOA();

                let color = [];

                if ( data.anual.length == 0 )
                {
                    this.tituloIndicOA = "Anual";

                    this.chartIndicOA.labels.push( "Sem dados" );
                    this.chartIndicOA.datasets[ 0 ].data.push( 1 );
                    this.chartIndicOA.datasets[ 0 ].backgroundColor.push( "#f5f5f5" );
                }
                else
                {
                    color              = [];
                    this.tituloIndicOA = data.anual[0].periodo_txt;

                    data.anual.forEach( el_s =>
                    {
                        let auxCr = randomColor();

                        while ( color.includes( auxCr ) ) auxCr = randomColor();

                        this.chartIndicOA.labels.push(this.formataIndicNenhum( el_s.tipo_indic ) );
                        this.chartIndicOA.datasets[ 0 ].data.push( this.formataValorFloat( el_s.valor ) );
                        this.chartIndicOA.datasets[ 0 ].backgroundColor.push( auxCr );
                    } );
                }
            },
            resetGraficoIndicacaoOA()
            {
                this.tituloIndicOA = "";
                this.chartIndicOA  = { labels: [], datasets: [{ label: "", data: [], backgroundColor: [] }] };
            },

            formataValorFloat( value )
            {
                value = value.replaceAll( ".", ""  );
                value = value.replaceAll( ",", "." );
                value = parseFloat( value );
                return value;
            },
            formataIndicNenhum( data )
            {
                if ( !data.includes( "Nenhum" ) )
                    return data;

                data = data.replace( "<<", ""  );
                return data.replace( " >>", "." );
            },
        },

        mounted()
        {
            axios
            .post(
                `${this.url}/easy-log`,
                {
                    route : this.$route.path,
                    code  : 200,
                },
            );

            this.carregarAnos();
            this.carregarPrestadores();
            this.carregarUnidades();
        }
    }
</script>

<style scoped >
</style>
