<template>

    <transition name="fade-view" mode="out-in" >
        <router-view
            v-bind:url="url"
            v-bind:user="user"
            v-bind:token="token"
            v-bind:clinic="clinic"
            v-bind:token-cpf="tokenCpf"
            v-bind:token-time="tokenTime"
            v-bind:exp-access="expAccess"
            v-bind:ed-login.sync="edLogin"
            v-bind:exp-document="expDocument"
            v-bind:exp-register="expRegister"
            v-bind:ed-user-sign.sync="edUserSign"
        ></router-view>
    </transition>

</template>

<script>
    export default {
        props: {
            user: Object,
            token: Boolean,
            clinic: Object,
            edLogin: String,
            tokenCpf: String,
            tokenTime: String,
            expAccess: String,
            isRedirect: Boolean,
            edUserSign: Boolean,
            expDocument: String,
            expRegister: String,
            url: { type: String, required: true },
        },

        created() {
            /**
             * Secretaria | role == null | acessa todas, menos /dashboard e /procedimentos-periodo
             * Admin      | role == 1    | acessa todas URLs
             * Dentista   | role == 2    | acessa apenas /agenda
             */
            const dts_routes = [ "/agenda" ];
            const adm_routes = [
                "/procedimentos-periodo",
                "/dashboard",
            ];

            if (
                ( this.user.role == 2    && !dts_routes.includes( this.$route.path ) && !this.isRedirect )
                ||
                ( this.user.role == null &&  adm_routes.includes( this.$route.path ) )
            )
            {
                this.$router.push( "/" );
            }
        },
    }
</script>

<style scoped >
    .fade-view-enter, .fade-view-leave-to {
        opacity: 0;
    }
    .fade-view-enter-active, .fade-view-leave-active {
        transition: opacity .5s ease-in-out;
    }
</style>
