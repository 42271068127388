<template>

    <div class="container" >
        <div class="row justify-content-center" >
            <div class="col-md-8" >
                <div class="card mt-2rem" >
                    <div class="card-body" >
                        <h4 class="ft-1rem mb-0" >Página não encontrada</h4>
                        <span>Erro 404</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import axios from "axios";

    export default {
        props:
        {
            url: {
                type: String,
                required: true
            },
        },

        mounted()
        {
            axios
            .post(
                `${this.url}/easy-log`,
                {
                    route : this.$route.path,
                    code  : 404,
                },
            );
        },
    }
</script>

<style scoped >
</style>
