<template>

    <section>
        <header class="d-flex align-items-center justify-content-center mt-2rem" style="font-family: 'Arial', sans-serif !important; font-size: 20px;" >
            <b>{{ this.clinic.nome }}</b>
        </header>

        <div class="form container-fluid" >
            <div class="row justify-content-center" >
                <div class="col-12 col-md-8 col-lg-4" v-if="fase === 0" >

                    <div class="card mt-2rem" >
                        <div class="card-header" >
                            <h4 class="ft-1rem mb-0" >Olá!</h4>
                        </div>
                        <div class="card-body" >
                            <p>Insira o número do seu CPF ou o ID do paciente.</p>

                            <img :src="'/img/login.png'" class="img-fluid mx-auto my-4 d-block img-login" style="height: 200px;" alt="Informe o seu CPF para continuar" />

                            <div class="form-row" >
                                <div class="form-group required col-md-12 col-12 mb-0" >
                                    <label for="nroSeach" >Informe o número:</label>
                                    <the-mask v-model="nroSeach" id="nroSeach" class="form-control" type="text" placeholder="CPF ou ID do paciente"
                                        v-bind:mask="['#########','###.###.###-##']" v-on:input="validExistingNID" v-bind:disabled="token" required />
                                </div>
                            </div>

                            <div class="carregamento" v-if="consultingReg" >
                                <div class="alert alert-info mt-4 mb-0" >
                                    <div class="d-flex align-items-center" >
                                        <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                            <span class="sr-only" >Loading...</span>
                                        </div>
                                        <span>Consultando cadastro...</span>
                                    </div>
                                </div>
                            </div>

                            <div class="alert alert-warning mt-4 mb-0" v-if="existingNID == false" >
                                <i class="fas fa-exclamation-circle" ></i> Ops! Ocorreu um erro na leitura dos dados.<br />Por favor retorne e tente novamente.
                            </div>

                            <div class="alert alert-success mt-4 mb-0" v-if="existingNID == true" >
                                <i class="fas fa-check-circle" ></i> Tudo certo!
                                Clique em Próximo para continuar.
                            </div>
                        </div>

                        <div class="card-footer" v-if="existingNID == null" >
                            <div class="float-right" >
                                <button class="btn btn-info" v-on:click="retrievePatient" v-bind:disabled="consultingReg" >
                                    Consultar
                                </button>
                            </div>
                        </div>

                        <div class="card-footer" v-if="existingNID == true" >
                            <div class="float-right" >
                                <button class="btn btn-success" v-on:click="next" >
                                    Próximo
                                </button>
                            </div>
                        </div>

                        <div class="card-footer" v-if="existingNID == false" >
                            <div class="float-right" >
                                <button class="btn btn-success" v-on:click="start" >
                                    Retornar
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <form v-on:submit.prevent="submitForm" >
                <div id="photo" class="card mt-2rem" v-show="fase === 1" >
                    <div class="card-header" >
                        <h4 class="ft-1rem mb-0" >Foto do paciente: {{ snome }}</h4>
                    </div>
                    <div class="card-body" >
                        <Camera ref="vueCamFile" maxResolution @isImageLoad="isImageLoad($event)" />

                        <div class="alert alert-info mt-3 mb-0" v-if="saving" >
                            <div class="spinner-border spinner-border-sm mr-2" role="status" ><span class="sr-only" >Loading...</span></div>
                            Enviando imagem...
                        </div>
                        <div class="alert mt-3 mb-0" v-bind:class="msgClass" v-if="msgShow" >{{ message }}</div>

                        <button type="submit" class="btn btn-success btn-block mt-3" v-bind:disabled="saving || !this.imageLoad" >Sallet imagem</button>
                        <button type="button" class="btn btn-info btn-block mt-3" v-on:click="start" >Retornar</button>
                    </div>
                </div>
            </form>
        </div>
    </section>

</template>

<script>
    import axios  from "axios";
    import moment from "moment";
    import Camera from "../Camera";

    export default {
        data()
        {
            return {
                headers       : { headers : { "Content-Type" : "application/json", Accept : "*" } },

                imageUrl      :  null,
                imageFile     :  null,
                imageName     :  "",
                imageLoad     :  null,

                saving        : null,
                message       : "Teste",
                msgClass      : "alert-success",
                msgShow       : false,

                fase          : 0,
                snome         : "",
                nropac        : "",
                nroSeach      : "",
                existingNID   : null,
                consultingReg : false,
            };
        },

        components:
        {
            Camera
        },

        props:
        {
            user: Object,
            token: Boolean,
            clinic: Object,
            tokenCpf: String,
            tokenTime: String,
            resetTokens: Function,
            url: { type: String, required: true },
        },

        methods:
        {
            start()
            {
                this.fase          = 0;
                this.snome         = "";
                this.nropac        = "";
                this.nroSeach      = "";
                this.existingNID   = null;
                this.consultingReg = false;

                this.reset();
            },
            reset()
            {
                this.imageUrl  = null;
                this.imageFile = null;
                this.imageName = "";

                this.saving    = null;
                this.message   = "";
                this.msgClass  = "";
                this.msgShow   = false;
            },
            next()
            {
                this.fase += 1;
            },

            formatDate()
            {
                // foto_ID_YYMMDDHHMMSS
                return moment().format("YYMMDDHHmmss");
            },

            validExistingNID()
            {
                if ( this.nroSeach.length == 0 )
                {
                    this.nropac        = "";
                    this.nroSeach      = "";
                    this.existingNID   = null;
                    this.consultingReg = false;
                }
            },

            retrievePatient()
            {
                if ( this.nroSeach.length == 0 )
                {
                    this.nropac        = "";
                    this.nroSeach      = "";
                    this.existingNID   = null;
                    this.consultingReg = false;
                }

                // this.nropac = 20;
                // this.snome  = "Mateus";
                // this.existingNID   = true;
                // this.consultingReg = false;

                let num            = this.nroSeach.replace(/\D/g, "");
                let iscpf          = (this.isCPF(num) && this.nroSeach.length == 11) ? true : false;
                this.consultingReg = true;

                axios
                .post(
                    `${this.url}/clinica/paciente/nropac`,
                    {
                        num: num,
                        cpf: iscpf ? true : false,
                    }
                )
                .then( response =>
                {
                    if ( response.data )
                    {
                        this.nropac = response.data.nropac;
                        this.snome  = response.data.snome.trimEnd();
                    }

                    this.existingNID   = true;
                    this.consultingReg = false;
                } )
                .catch( err =>
                {
                    console.log( `retrievePatientNroPac(), ${err.name}: ${err.message}` );

                    this.existingNID   = false;
                    this.consultingReg = false;
                } );
            },

            isImageLoad( imageLoad )
            {
                this.imageLoad = imageLoad;
            },

            submitForm()
            {
                this.msgShow   = false;
                this.saving    = true;

                this.imageName = `img_${this.nropac}_${this.formatDate()}.png`;

                const wcam     = this.$refs.vueCamFile.camOption;
                const file     = this.$refs.vueCamFile.fileOption;

                let data       = {};
                let content    = "";

                if ( wcam )
                {
                    this.imageUrl = this.$refs.vueCamFile.photoUrl;

                    content       = "application/json";
                    data          = {
                        imageFile: this.imageUrl,
                        imageName: this.imageName,
                        nropac:    this.nropac,
                        isFile:    false,
                    };
                }
                else if ( file )
                {
                    this.imageFile = this.$refs.vueCamFile.fileObj;
                    this.imageUrl  = this.$refs.vueCamFile.fileUrl;

                    let formData   = new FormData();

                    formData.append( "imageFile", this.imageFile );
                    formData.append( "imageName", this.imageName );
                    formData.append( "nropac",    this.nropac    );
                    formData.append( "isFile",    true           );

                    data           = formData;
                    content        = "multipart/form-data";
                }
                else
                {
                    this.showMessage( "alert-warning", "Você ainda não tirou uma foto!" );
                    return;
                }

                axios
                .post(
                    `${this.url}/clinica/paciente/imagem`,
                    data,
                    this.headers
                )
                .then( response =>
                {
                    if ( response.status === 200 )
                    {
                        this.showMessage( "alert-success", "Imagem enviada com sucesso." );

                        setTimeout( ( () =>
                        {
                            this.reset();
                            this.$refs.vueCamFile.restartCamera();
                        } ), 2500 );
                    }
                    else
                    {
                        this.showMessage( "alert-warning", "Ops! Aconteceu um erro ao enviar a imagem." );

                        setTimeout( ( () =>
                        {
                            this.reset();
                            this.$refs.vueCamFile.restartCamera();
                        } ), 2500 );
                    }
                } )
                .catch( err =>
                {
                    console.log( `recordPhoto(), ${err.name}: ${err.message}` );

                    this.showMessage( "alert-warning", "Ops! Aconteceu um erro ao enviar a imagem." );
                } );
            },

            showMessage( cls, msg )
            {
                this.msgShow  = true;
                this.msgClass = cls;
                this.message  = msg;
                this.saving   = false;
            },

            isCPF( num )
            {
                let rest;
                let sum = 0;

                if ( num.length < 11 )
                    return false;

                const url = window.location.href;

                if ( url.indexOf( "homolog" ) !== -1 || url.indexOf( "192.168.15" ) !== -1 )
                    return true;

                if ( num == "00000000000" )
                    return false;

                for ( let i = 1; i <= 9; i++ )
                    sum = sum + parseInt( num.substring( i - 1, i ) ) * ( 11 - i );

                rest = ( sum * 10 ) % 11;

                if ( ( rest == 10 ) || ( rest == 11 ) )
                    rest = 0;

                if ( rest != parseInt( num.substring( 9, 10 ) ) )
                    return false;

                sum = 0;

                for ( let i = 1; i <= 10; i++ )
                    sum = sum + parseInt( num.substring( i - 1, i ) ) * ( 12 - i );

                rest = ( sum * 10 ) % 11;

                if ( ( rest == 10 ) || ( rest == 11 ) )
                    rest = 0;

                if ( rest != parseInt( num.substring( 10, 11 ) ) )
                    return false;

                return true;
            }
        },

        created()
        {
            if ( this.tokenTime )
            {
                const tkTime = moment( this.tokenTime );
                const now    = moment();
                const diff   = now.diff( tkTime, "seconds" );

                // Valida o tempo após o primeiro acesso em segundos (3600s == 60 min)
                if ( diff > 3600 )
                    window.location.href = "nao_autorizado/Link expirado!";
            }
        },

        mounted()
        {
            axios
            .post(
                `${this.url}/easy-log`,
                {
                    route : this.$route.path,
                    code  : 200,
                },
            );

            if ( this.tokenCpf )
                this.scpf = this.tokenCpf.split( "||" )[ 1 ];
        },
    };
</script>

<style scoped >
    .alert   { display: flex; align-items: center; }
    .alert i { margin-right: 1rem; }

    .img-login { max-width: 300px; text-align: center; }

    .loader { border: 5px solid #F3F3F3; /* Light grey */ border-top: 5px solid #555; /* Blue */ border-radius: 50%; width: 30px; height: 30px; animation: spin 2s linear infinite; display: inline-block; margin-right: 1rem; }

    @keyframes spin {
        0%   { transform: rotate(0deg);   }
        100% { transform: rotate(360deg); }
    }
</style>
