<template>
    <div class="row" >
        <div class="col-12" >
            <p class="mb-0" >{{ this.title }}:</p>
            <p class="mb-0" ><small id="img-fail" class="text-danger d-none" ></small></p>
        </div>
        <div class="col-12" >
            <button id="img-cam-btn"  type="button" class="btn btn-info mt-3"    v-bind:class="{ 'd-none': this.btnCam }"  v-bind:disabled="fileOption" v-on:click="openCamera" >
                <i class="fas fa-camera fa-lg" ></i>
            </button>
            <button id="img-file-btn" type="button" class="btn btn-primary mt-3" v-bind:class="{ 'd-none': this.btnFile }" v-bind:disabled="camOption"  v-on:click="chooseImage" >
                <i class="fas fa-images fa-lg" ></i>
            </button>
        </div>
        <div class="col-12 text-center" >
            <button id="img-cam-btn-take" type="button" class="btn btn-success mt-3 d-none" v-bind:disabled="photoTaken" v-on:click="takePhoto" >
                Tirar foto
            </button>
            <button id="img-cam-btn-undo" type="button" class="btn btn-primary mt-3 d-none" v-bind:disabled="!photoTaken" v-on:click="restartCamera" >
                <i class="fas fa-undo-alt fa-lg" ></i>
            </button>
            <button id="img-switch-cam" type="button" class="btn btn-info mt-3 d-none" v-bind:disabled="photoTaken" v-on:click="switchCamera" >
                <i class="fas fa-camera fa-lg" ></i><i class="fas fa-sync-alt fa-lg" ></i>
            </button>
            <button id="img-cam-btn-cancel" type="button" class="btn btn-warning mt-3 d-none" v-on:click="closeCamera" >
                <i class="fas fa-times fa-lg" ></i>
            </button>

            <button id="img-file-btn-cancel" type="button" class="btn btn-warning mt-3 d-none" v-on:click="clearImage" >
                <i class="fas fa-times fa-lg" ></i>
            </button>
        </div>
        <div class="col-12" >
            <div id="img-cam-preview" class="text-center mt-3 d-none" >
                <div class="row" >
                    <div class="col-12 col-sm-8 mx-auto" >
                        <video id="img-cam" class=" rounded" ></video>
                        <div id="img-preview" class="text-center d-none" >
                            <canvas class="text-center rounded" ></canvas>
                        </div>
                    </div>
                </div>
            </div>

            <div id="img-file-preview" class="text-center mt-3 d-none" >
                <div class="row" >
                    <div class="col-12 col-sm-8 mx-auto" >
                        <img v-if="fileUrl" v-bind:src="fileUrl" class="text-center rounded w-100 h-100" >
                    </div>
                </div>
            </div>
        </div>
        <input id="realInputFile" type="file" accept="image/*" class="custom-file-input d-none" v-on:change="onFileChange" >
    </div>
</template>

<script>
    // import adapter from 'webrtc-adapter';

    export default {
        data() {
            return {
                camera:     null,
                photoUrl:   null,
                camOption:  false,
                photoTaken: false,
                facingMode: "environment",
                srcWH:      null,

                fileObj:    null,
                fileUrl:    null,
                fileOption: false,

                image: {
                    file: null,
                    url:  null,
                },

                btnCam:  false,
                btnFile: false,
            }
        },

        // components: { adapter },

        props: {
            title: {
                type: String,
                default: 'Tire uma foto ou selecione uma da galeria'
            },
            // onlyCamera: {
            //     type: Boolean,
            //     default: false,
            // },
            maxResolution: {
                type: Boolean,
                default: false,
            },
            hideBtns: {
                type: Array,
                default: null,
            }
        },

        methods: {
            // FILE METHODS
            chooseImage() {
                this.camOption  = false;

                this.fileOption = true;
                $("#realInputFile").val("");
                $('#realInputFile').click();

                // $("#img-file-preview").removeClass("d-none");
                $("#img-file-btn-cancel").removeClass("d-none");
            },
            clearImage() {
                this.fileObj    = null;
                this.fileUrl    = null;

                if ($("#img-fail").hasClass("d-none"))
                    this.fileOption = false;
                else
                    this.fileOption = true;

                $("#realInputFile").val("");

                $("#img-file-preview").addClass("d-none");
                $("#img-file-btn-cancel").addClass("d-none");

                this.$emit("isImageLoad", false);
            },
            onFileChange(e) {
                const file   = e.target.files[0];
                this.fileObj = file;
                this.fileUrl = URL.createObjectURL(file);

                $("#img-file-preview").removeClass("d-none");
                // $("#img-file-btn-cancel").removeClass("d-none");

                this.$emit("isImageLoad", true);
            },

            // encerra todas streams abertas;
            killCamera() {
                if (window.stream)
                    stream.getTracks().forEach((track) => { track.stop() });
            },

            // CAMERA METHODS
            openCamera() {
                this.fileOption = false;

                this.camOption  = true;
                this.photoTaken = false;

                $("#img-fail").html("");

                if ("mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices)
                {
                    this.startCamera();

                    if (!this.btnCam)
                        $("#img-cam-btn-cancel").removeClass("d-none");

                    $("#img-switch-cam").removeClass("d-none")
                    $("#img-cam-preview").removeClass("d-none");
                    $("#img-cam-btn-undo").removeClass("d-none");
                    $("#img-cam-btn-take").removeClass("d-none");

                    $("#img-cam").removeClass("d-none");
                    $("#img-preview").addClass("d-none");
                }
                // else
                // {
                //     console.log("Origin not allowed!");
                //     $("#img-fail").html("Câmera não permitida!");
                //     $("#realInputFile").click();
                // }
            },
            startCamera(facing = "environment") {
                this.killCamera();
                this.camera     = document.querySelector("#img-cam");
                const constrain = (this.maxResolution) ? {
                    audio: false,
                    video: {
                        width:      { min: 1080, ideal: 2160, max: 3840 },
                        height:     { min: 1080, ideal: 2160, max: 3840 },
                        facingMode: facing
                    }
                } : {
                    audio: false,
                    video: {
                        width:      { ideal: 512 },
                        height:     { ideal: 512 },
                        facingMode: facing
                    }
                };

                navigator.mediaDevices.getUserMedia(constrain)
                .then(stream => {
                    window.stream = stream;

                    if ("srcObject" in this.camera)
                        this.camera.srcObject = stream;
                    else
                        this.camera.src       = window.URL.createObjectURL(stream);

                    this.srcWH = this.camera.srcObject.getVideoTracks()[0].getSettings();
                    this.camera.play();
                })
                .catch( err => {
                    console.log( `${err.name}: ${err.message}` );

                    let msg = (err.name == "NotAllowedError") ?
                        "Câmera não permitida" :
                            ((err.name == "NotFoundError") ?
                                "Câmera não detectada" :
                                    "Câmera não pode ser ativada");

                    // msg = (this.onlyCamera) ? msg : `${msg} Selecione uma foto da galeria.`;
                    msg = `${msg}! Selecione uma foto da galeria.`;

                    $("#img-fail").html(msg).removeClass("d-none");

                    this.camOption  = false;
                    this.fileOption = true;

                    if (!this.btnCam)
                        $("#img-cam-btn-cancel").addClass("d-none");

                    $("#img-switch-cam").addClass("d-none");
                    $("#img-cam-preview").addClass("d-none");
                    $("#img-cam-btn-undo").addClass("d-none");
                    $("#img-cam-btn-take").addClass("d-none");

                    $("#img-cam").addClass("d-none");
                    $("#img-preview").removeClass("d-none");
                } );
            },
            switchCamera() {
                this.facingMode = (this.facingMode == "environment") ? "user" : "environment";
                this.startCamera(this.facingMode);
            },
            restartCamera() {
                this.photoUrl   = false;
                this.photoTaken = false;
                this.startCamera(this.facingMode);

                $("#img-cam").removeClass("d-none");
                $("#img-preview").addClass("d-none");
            },
            closeCamera() {
                this.camOption  = false;
                this.photoTaken = false;

                this.camera.srcObject.getVideoTracks()[0].stop();
                this.camera.srcObject = null;

                if (!this.btnCam)
                    $("#img-cam-btn-cancel").addClass("d-none");

                $("#img-switch-cam").addClass("d-none");
                $("#img-cam-btn-undo").addClass("d-none");
                $("#img-cam-btn-take").addClass("d-none");
                $("#img-cam-preview").addClass("d-none");

                $("#img-cam").removeClass("d-none");
                $("#img-preview").addClass("d-none");

                this.$emit("isImageLoad", false);
            },
            takePhoto() {
                this.photoTaken = true;

                $("#img-cam").addClass("d-none");
                $("#img-preview").removeClass("d-none");

                const width   = (this.maxResolution ? this.srcWH.width  : 512);
                const height  = (this.maxResolution ? this.srcWH.height : 512);

                const canvas  = document.querySelector("#img-preview canvas");
                const context = canvas.getContext("2d");
                let data      = null;

                canvas.width  = width;
                canvas.height = height;

                context.drawImage(this.camera, 0, 0, width, height);

                this.camera.srcObject.getVideoTracks()[0].stop();

                this.photoUrl = data = canvas.toDataURL("image/png");

                canvas.setAttribute("src", data);

                this.$emit("isImageLoad", true);
            },
        },

        async mounted() {
            this.killCamera();

            if (this.hideBtns)
            {
                this.hideBtns.forEach( e => {
                    if ( e == "C" )
                        this.btnCam  = true;

                    if ( e == "F" )
                        this.btnFile = true;

                });
            }

        },
    }
</script>

<style scoped >

    /* #img-file-preview img { max-width: 100%; max-height: 500px; } */
    #img-cam { margin: 0 auto !important; max-width: 100% !important; }
    #img-preview canvas { width: 100% !important; height: 100% !important; }

    @media (max-width: 576px) {
        #img-cam { max-width: 75%; }
    }

    @media (max-width: 768px) {
        #img-cam { max-width: 60%; }
    }

    @media (max-width: 992px) {
        #img-cam { max-width: 50%; }
    }

</style>
