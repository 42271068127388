<template>

    <section>
        <header class="d-flex align-items-center justify-content-center mt-2rem" style="font-family: 'Arial', sans-serif !important; font-size: 20px;" >
            <b>{{ this.clinic.nome }}</b>
        </header>

        <div class="form container-fluid" >
            <div class="row justify-content-center" >
                <!-- FASE 0 -->
                <div class="col-12 col-md-8 col-lg-6 col-xl-4" v-if="fase === 0" >
                    <div class="card mt-2rem" >
                        <div class="card-header" >
                            <h4 class="ft-1rem mb-0" >Entrar com seu login do EasyDental</h4>
                        </div>
                        <div class="card-body" >
                            <img v-bind:src="'/img/login.png'" class="img-fluid mx-auto my-4 d-block img-login" style="height: 200px;" alt="Entrar com seu login do EasyDental" />

                            <div class="form-row" >
                                <div class="form-group required col-12" >
                                    <label for="login" >Login</label>
                                    <input v-model="login" id="login" type="text" class="form-control" required autofocus
                                        autocomplete="ed-login" v-bind:disabled="consultingUser" >
                                </div>
                            </div>
                            <div class="form-row" >
                                <div class="form-group requires col-12 mb-0" >
                                    <label for="passw" >Senha</label>
                                    <input v-model="passw" id="passw" type="password" class="form-control" required
                                        autocomplete="ed-password" v-bind:disabled="consultingUser" >
                                </div>
                            </div>

                            <div class="carregamento" v-if="consultingUser" >
                                <div class="alert alert-info mt-4 mb-0" >
                                    <div class="d-flex align-items-center" >
                                        <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                            <span class="sr-only" >Loading...</span>
                                        </div>
                                        <span>Consultando cadastro...</span>
                                    </div>
                                </div>
                            </div>

                            <div class="alert alert-warning mt-4 mb-0" v-if="existingUser == false" >
                                <i class="fas fa-exclamation-circle" ></i>
                                {{ this.errorMsg ?? "Ops! Ocorreu um erro na consulta dos dados." }}
                            </div>

                            <div class="alert alert-success mt-4 mb-0" v-if="existingUser == true" >
                                <i class="fas fa-check-circle" ></i>
                                Tudo certo!
                            </div>
                        </div>

                        <div class="card-footer" v-if="existingUser == null" >
                            <div class="float-right" >
                                <button class="btn btn-info" v-on:click="loginUser" v-bind:disabled="consultingUser" >Entrar</button>
                            </div>
                        </div>

                        <div class="card-footer" v-if="existingUser == false" >
                            <div class="float-right" >
                                <button class="btn btn-success" v-on:click="start" >Recarregar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- FASE 1 -->
                <div class="col-12 col-md-8 col-lg-6 col-xl-4" v-if="fase === 1" >
                    <div class="card mt-2rem" >

                        <div class="card-header" >
                            <h4 class="float-left ft-1rem mb-0" >Receita digital</h4>
                            <div class="float-right" >
                                <span class="my-auto" >{{ this.edLogin }}</span> | <button class="btn btn-link btn-sm p-0" v-on:click="logoutUser" >Sair</button>
                            </div>
                        </div>

                        <div class="card-body" >
                            <p>Insira o CPF do paciente, para preenchermos a receita.</p>
                            <div class="form-row" >
                                <div class="form-group required col-md-12 col-12 mb-0" >
                                    <input v-model="scpf" id="scpf" class="form-control" type="text" placeholder="ex: 000.000.000-00"
                                        v-mask="'###.###.###-##'" required v-bind:disabled="consultingPatient || ( tokenCpf.length > 0 && isRedirectFromAgenda === true )" />
                                </div>
                            </div>

                            <div class="carregamento" v-if="consultingPatient" >
                                <div class="alert alert-info mt-4 mb-0" >
                                    <div class="d-flex align-items-center" >
                                        <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                            <span class="sr-only" >Loading...</span>
                                        </div>
                                        <span>Consultando cadastro...</span>
                                    </div>
                                </div>
                            </div>

                            <div class="alert alert-warning mt-4 mb-0" v-if="existingPatient == false" >
                                <i class="fas fa-exclamation-circle" ></i>
                                {{ this.errorMsg ?? "Ops! Ocorreu um erro na consulta dos dados." }}
                            </div>

                            <div class="alert alert-success mt-4 mb-0" v-if="existingPatient == true" >
                                <i class="fas fa-check-circle" ></i>
                                Tudo certo!
                            </div>
                        </div>

                        <div class="card-footer" v-if="existingPatient == null" >
                            <div class="float-right" >
                                <button class="btn btn-info" v-on:click="consultPatient" v-bind:disabled="consultingPatient" >Consultar</button>
                            </div>
                        </div>
                        <div class="card-footer" v-if="existingPatient == false" >
                            <div class="float-right" >
                                <button class="btn btn-success" v-on:click="refresh" >Recarregar</button>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- FASE 2 -->
                <div class="col-12 col-md-8 col-xl-6" v-if="fase === 2" >
                    <div class="card mt-2rem" >

                        <div class="card-header" >
                            <h4 class="float-left ft-1rem mb-0" >Receita digital</h4>
                        </div>
                        <div class="card-body" >

                            <div class="form-group row" >
                                <div class="col-12" >
                                    <label for="prescriptionType" >Tipo da receita</label>
                                    <select v-model="prescriptionType" id="prescriptionType" class="form-control" type="text" required >
                                        <option value="simple" selected >Receituário Simples</option>
                                        <option value="special" >Receituário de Controle Especial</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group row" >
                                <div class="col-12" >
                                    <label for="medicationList" >Medicamento</label>
                                    <select v-model="medSelected" id="medicationList" class="form-control" type="text" required >
                                        <option v-for="( item, i ) in medicationList" v-bind:key="i" v-bind:value="i" >
                                            {{ item[ 'nm_medic' ] }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-12" >
                                    <div class="float-right mt-2" >
                                        <button id="btnMedicationList" class="btn btn-primary" v-on:click="addMedicationList" >Adicionar</button>
                                    </div>
                                </div>
                            </div>

                            <hr>

                            <div class="form-group row mb-0" >
                                <div class="col-12" >
                                    <label for="" >Pré-visualização</label>
                                    <p class="mb-0" >Para</p>
                                    <p class="mb-0" >{{ this.snome }} - {{ this.scpf }}</p>
                                    <p class="mb-0" >{{ this.send }}, {{ this.scomplem }}, {{ this.sbai }}, {{ this.scid }} - {{ this.suf }}</p>
                                    <p class="mb-0" >{{ this.scep }}</p>
                                    <br>

                                    <p class="mb-0" v-if="!dataList.length" >
                                        Sem medicamentos adicionados
                                    </p>

                                    <div v-if="dataList.length" >
                                        <div class="medicList" v-for="( medItem, y ) in dataList" v-bind:key="y" >

                                            <i class="far fa-lg fa-times-circle text-danger"
                                                v-bind:id="`show-btn-delete-${y}`" v-on:click="processMedicList( y, 'delete' )" ></i>

                                            <i class="far fa-lg fa-edit text-primary"
                                                v-bind:id="`show-btn-edit-${y}`" v-on:click="processMedicList( y, 'edit'   )" ></i>

                                            <i class="far fa-lg fa-save text-success d-none"
                                                v-bind:id="`show-btn-save-${y}`" v-on:click="processMedicList( y, 'save'   )" ></i>

                                            <p class="mb-0" >{{ y + 1 }}. {{ medItem[ 'nm_medic' ] }}</p>

                                            <p class="mb-0" v-bind:id="`preview-medic-${y}`" v-html="dataList[ y ][ 'pos_medic_1' ]" ></p>

                                            <textarea v-bind:id="`descr-medic-${y}`" v-model="dataList[ y ][ 'pos_medic' ]" class="form-control d-none" rows="3" placeholder="Descreva" ></textarea>

                                            <br v-if="y != dataList.length - 1" >
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="this.edUserSign == true" >
                                <hr>

                                <div class="form-group row" >
                                    <div class="col-12" >
                                        <div class="form-group form-check checkbox mb-0" >
                                            <input id="notify" type="checkbox" class="form-check-input" v-model="notify" >
                                            <label class="form-check-label" for="notify" >Desejo enviar uma cópia por e-mail</label>
                                        </div>
                                    </div>
                                    <div class="col-12" >
                                        <div class="form-row" v-if="notify === true" >
                                            <div class="form-group required col-12 mb-0 mt-3" >
                                                <input v-model="emailNotify" id="emailNotify" class="form-control" type="email" placeholder="Informe o e-mail do destinatário para receber a cópia" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr>

                                <div class="form-group row mb-0" >
                                    <div class="col-12" >
                                        <label for="passcert" >Senha certificado</label>
                                        <input v-model="passcert" id="passcert" type="password" class="form-control"
                                            placeholder="Sua senha do certificado digital" required autocomplete="certificate-password"
                                            v-bind:disabled="this.prescriptionType === null || this.dataList.length === 0" />
                                    </div>
                                </div>
                            </div>

                            <div class="carregamento" v-if="sendingData" >
                                <div class="alert alert-info mt-4 mb-0" >
                                    <div class="d-flex align-items-center" >
                                        <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                            <span class="sr-only" >Loading...</span>
                                        </div>
                                        <span>Enviando dados...</span>
                                    </div>
                                </div>
                            </div>

                            <div class="alert alert-info mt-4 mb-0" v-if="dataSent == false && partialSuccessMsg != null" >
                                <i class="fas fa-exclamation-circle" ></i>
                                {{ this.partialSuccessMsg }}
                            </div>
                            <div class="alert alert-warning mt-4 mb-0" v-if="dataSent == false" >
                                <i class="fas fa-exclamation-circle" ></i>
                                {{ this.errorMsg ?? "Ops! Ocorreu um erro no envio dos dados." }}
                            </div>
                            <div class="alert alert-success mt-4 mb-0" v-if="dataSent == true" >
                                <i class="fas fa-check-circle" ></i>
                                Receita digital gerada com sucesso!
                            </div>


                            <a class="text-decoration-none text-white" v-bind:href="this.linkPrescription" target="_blank" rel="noopener noreferrer" >
                                <div class="alert alert-info mt-4 mb-0" v-if="this.linkPrescription != null" >
                                    <i class="fas fa-exclamation-circle" ></i>
                                    Clique aqui para acessar a receita digital.
                                </div>
                            </a>
                        </div>

                        <div class="card-footer" >
                            <div class="float-left" v-if="isRedirectFromAgenda === false" >
                                <button class="btn btn-info" v-on:click="refresh" >Voltar</button>
                            </div>
                            <div class="float-right" >
                                <button id="btnDataSent" v-bind:class="`btn ${this.btnFinishClass}`" v-on:click="submitData"
                                    v-bind:disabled="this.prescriptionType === null || this.dataList.length === 0" >
                                    {{ this.btnFinishText }}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </section>

</template>

<script>
    import axios from "axios";

    export default {
        data()
        {
            return {
                headers              : { headers : { "Content-Type" : "application/json", Accept : "*" } },

                fase                 :  0,
                login                : "",
                passw                : "",
                passcert             : "",

                scpf                 : null,
                snome                : "",
                nropac               : 0,
                send                 : "",
                scomplem             : "",
                sbai                 : "",
                scid                 : "",
                suf                  : "",
                scep                 : "",

                errorMsg             : null,
                partialSuccessMsg    : null,

                existingUser         : null,
                consultingUser       : false,

                existingPatient      : null,
                consultingPatient    : false,

                dataList             : [],
                prescriptionType     : null,
                medSelected          : null,
                medicationList       : [],

                dataSent             : null,
                sendingData          : null,

                isRedirectFromAgenda : false,

                notify               : null,
                emailNotify          : null,

                btnFinishText        : "",
                btnFinishClass       : "",
                btnAllowFinish       : null,

                linkPrescription     : null,
            };
        },

        props:
        {
            clinic: Object,
            edLogin: String,
            tokenCpf: String,
            edUserSign: Boolean,
            url: { type: String, required: true },
        },

        methods:
        {
            start()
            {
                this.fase              = 0;
                this.login             = "";
                this.passw             = "";
                this.passcert          = "";
                this.scpf              = null;
                this.nropac            = 0;
                this.send              = "";
                this.scomplem          = "";
                this.sbai              = "";
                this.scid              = "";
                this.suf               = "";
                this.scep              = "";

                this.errorMsg          = null;

                this.existingUser      = null;
                this.consultingUser    = false;

                this.existingPatient   = null;
                this.consultingPatient = false;

                this.showBtnDelete     = true;
                this.showBtnEdit       = true;
                this.showBtnSave       = false;

                this.notify            = null;
                this.emailNotify       = null;

                this.btnFinishText     = "";
                this.btnFinishClass    = "";
                this.btnAllowFinish    = null;

                this.linkPrescription  = null;
            },
            next()
            {
                this.fase += 1;
            },
            refresh()
            {
                this.fase              = 1;
                this.scpf              = null;
                this.nropac            = 0;
                this.send              = "";
                this.scomplem          = "";
                this.sbai              = "";
                this.scid              = "";
                this.suf               = "";
                this.scep              = "";

                this.errorMsg          = null;
                this.partialSuccessMsg = null;

                this.existingUser      = null;
                this.consultingUser    = false;

                this.existingPatient   = null;
                this.consultingPatient = false;

                this.dataList          = [];
                this.prescriptionType  = null;
                this.medSelected       = null;

                this.dataSent          = null;
                this.sendingData       = null;

                this.showBtnDelete     = true;
                this.showBtnEdit       = true;
                this.showBtnSave       = false;

                this.notify            = null;
                this.emailNotify       = null;

                this.linkPrescription  = null;

                // this.btnFinishText     = "";
                // this.btnFinishClass    = "";
                // this.btnAllowFinish    = null;

                $( "#btnDataSent" ).show();
            },

            loginUser()
            {
                if ( this.login.length == 0 || this.passw.length == 0 )
                {
                    this.login          = "";
                    this.passw          = "";
                    this.existingUser   = null;
                    this.consultingUser = false;
                }
                else
                {
                    this.consultingUser = true;

                    axios
                    .post(
                        `${this.url}/easydental/login`,
                        {
                            login  : this.login,
                            passw  : this.passw,
                            option : "receitadigital",
                        }
                    )
                    .then( response =>
                    {
                        let data = response.data.data;

                        if ( data.nprest != 0 )
                        {
                            this.$emit( "update:edLogin",    data.user );
                            this.$emit( "update:edUserSign", data.sign );

                            if ( data.medicationList.length > 0 )
                                this.medicationList = data.medicationList;

                            if ( data.sign == true )
                            {
                                this.btnFinishText  = "Finalizar c/ assinatura";
                                this.btnFinishClass = "btn-success";
                                this.btnAllowFinish = true;
                            }
                            else
                            {
                                this.btnFinishText  = "INATIVO";
                                this.btnFinishClass = "btn-danger disabled";
                                this.btnAllowFinish = false;
                            }

                            this.existingUser   = true;
                            this.consultingUser = false;
                            this.next();
                        }
                        else
                        {
                            this.existingUser   = false;
                            this.errorMsg       = "Você não as permissões necessárias para acessar essa área!";
                            this.consultingUser = false;
                        }
                    } )
                    .catch( err =>
                    {
                        console.log( `login(), ${err.name}: ${err.message}` );

                        this.existingUser   = false;
                        this.consultingUser = false;
                        this.consultingUser = false;
                    } );
                }
            },
            logoutUser()
            {
                axios
                .post( `${this.url}/easydental/logout` )
                .then( response =>
                {
                    this.existingUser   = false;
                    this.consultingUser = false;
                    this.$emit( "update:edLogin",    ""    );
                    this.$emit( "update:edUserSign", false );
                    this.start();
                } )
                .catch( err =>
                {
                    console.log( `logout(), ${err.name}: ${err.message}` );

                    this.existingUser   = false;
                    this.consultingUser = false;
                } );
            },

            consultPatient()
            {
                let treatedCPF = this.scpf.replace(/\D/g, "");

                if ( this.scpf.length == 14 )
                {
                    if ( this.medicationList.length == 0 )
                        this.getMedicationList();

                    this.consultingPatient = true;

                    axios
                    .get( `${this.url}/clinica/paciente/${treatedCPF}` )
                    .then( response =>
                    {
                        let data = response.data;

                        if ( data.nropac &&
                            !( data.sanot.length   === 0 ) &&
                            !( data.nconv.length   === 0 ) &&
                            !( data.ntpind.length  === 0 ) &&
                            !( data.nestciv.length === 0 ) )
                        {
                            this.existingPatient = true;
                            this.nropac          = data.nropac;
                            this.snome           = data.snome;
                            this.scpf            = data.scpf;

                            this.send            = data.send;     // rua e numero
                            this.scomplem        = data.scomplem; // complemento
                            this.sbai            = data.sbai;     // bairro
                            this.scid            = data.scid;     // cidade
                            this.suf             = data.suf;      // estado
                            this.scep            = data.scep;     // CEP

                            this.next();
                        }
                        else
                        {
                            this.existingPatient = false;
                        }

                        this.consultingPatient = false;
                    } )
                    .catch( err =>
                    {
                        console.log( `retrievePatient(), ${err.name}: ${err.message}` );

                        this.existingPatient   = false;
                        this.consultingPatient = false;
                    } );
                }

                if ( this.scpf.length == 0 )
                {
                    this.consultingPatient = false;
                    this.existingPatient   = null;
                    this.scpf              = "";
                }
            },

            getMedicationList()
            {
                axios
                .get( `${this.url}/clinica/listamedicamentos` )
                .then( response =>
                {
                    this.medicationList = response.data.data;
                } )
                .catch( err =>
                {
                    console.log( `retrieveMedicationList(), ${err.name}: ${err.message}` );
                } );
            },

            addMedicationList()
            {
                this.dataList.push( this.medicationList[ this.medSelected ] );
            },

            submitData()
            {
                if ( !this.btnAllowFinish )
                    return;

                $( "#passcert"          ).attr( "disabled", true );
                $( "#btnDataSent"       ).attr( "disabled", true );
                $( "#medicationList"    ).attr( "disabled", true );
                $( "#prescriptionType"  ).attr( "disabled", true );
                $( "#btnMedicationList" ).attr( "disabled", true );

                this.errorMsg          = null;
                this.partialSuccessMsg = null;

                this.dataSent          = null;
                this.sendingData       = true;

                axios
                .post(
                    `${this.url}/clinica/receita`,
                    {
                        scpf             : this.scpf,
                        snome            : this.snome,
                        nropac           : this.nropac,
                        send             : this.send,
                        scomplem         : this.scomplem,
                        sbai             : this.sbai,
                        scid             : this.scid,
                        suf              : this.suf,
                        scep             : this.scep,

                        prescriptionType : this.prescriptionType,
                        medicationList   : this.dataList,
                        passcert         : this.passcert,

                        notify           : this.notify,
                        emailNotify      : this.notify ? this.emailNotify : "",
                    },
                    this.headers
                )
                .then( response =>
                {
                    this.treatResponseSuccess( response.data );
                } )
                .catch( err =>
                {
                    console.log( `postPrescription(), ${err.name}: ${err.message}` );

                    this.treatResponseError( err.response.data );

                    // const response   = err.response.data;
                    //
                    // this.dataSent    = false;
                    // this.sendingData = false;
                    //
                    // if ( response.data != null )
                    // {
                    //     if ( response.data.message_file != "" )
                    //         this.errorMsg          = response.data.message_file;
                    //     else
                    //         this.partialSuccessMsg = "Receita enviada para o Easy Dental com sucesso!";
                    //
                    //     if ( response.data.message_notification != "" )
                    //         this.errorMsg          = ( this.errorMsg != null )
                    //                                  ? `${this.errorMsg} ${response.data.message_notification}`
                    //                                  : response.data.message_notification;
                    //     else
                    //         this.partialSuccessMsg = "Receita enviada por e-mail com sucesso!";
                    // }
                    // else
                    // {
                    //     this.errorMsg = response.message;
                    // }
                    //
                    // $( "#passcert"          ).removeAttr( "disabled" );
                    // $( "#btnDataSent"       ).removeAttr( "disabled" );
                    // $( "#medicationList"    ).removeAttr( "disabled" );
                    // $( "#prescriptionType"  ).removeAttr( "disabled" );
                    // $( "#btnMedicationList" ).removeAttr( "disabled" );
                } );
            },

            processMedicList( index, action )
            {
                switch ( action )
                {
                    case "delete":
                        this.dataList.splice( index, 1 );
                    break;

                    case "edit":
                        $( `#show-btn-delete-${index}` ).addClass( "d-none" );
                        $( `#show-btn-edit-${index}`   ).addClass( "d-none" );
                        $( `#show-btn-save-${index}`   ).removeClass( "d-none" );

                        $( `#preview-medic-${index}`   ).addClass( "d-none" );
                        $( `#descr-medic-${index}`     ).removeClass( "d-none" );
                    break;

                    case "save":
                        let text = this.dataList[ index ][ "pos_medic" ];

                        this.dataList[ index ][ "pos_medic_1" ] = text.replace( /\n/g, "<br>" );

                        $( `#show-btn-delete-${index}` ).removeClass( "d-none" );
                        $( `#show-btn-edit-${index}`   ).removeClass( "d-none" );
                        $( `#show-btn-save-${index}`   ).addClass( "d-none" );

                        $( `#preview-medic-${index}`   ).removeClass( "d-none" );
                        $( `#descr-medic-${index}`     ).addClass( "d-none" );
                    break;
                }
            },

            treatResponseSuccess( response )
            {
                this.dataSent         = true;
                this.sendingData      = false;
                this.linkPrescription = response.data.file;
            },
            treatResponseError( response )
            {
                if ( response.code == "400SFL" )
                    this.setError();
                else if ( response.code == "400RD.PP" || response.exception )
                    this.setError( response.message );
                else
                    this.treatResponseSuccess( response );
            },
            setError( err )
            {
                const message = ( err )
                    ? "Ops! Aconteceu um erro ao gerar a receita digital. Contate o suporte."
                    : "Ops! Aconteceu um erro ao gerar a receita digital. Tente novamente.";

                this.dataSent         = false;
                this.sendingData      = false;
                this.errorMsg         = message;
                this.linkPrescription = null;

                $( "#passcert"          ).removeAttr( "disabled" );
                $( "#btnDataSent"       ).removeAttr( "disabled" );
                $( "#medicationList"    ).removeAttr( "disabled" );
                $( "#prescriptionType"  ).removeAttr( "disabled" );
                $( "#btnMedicationList" ).removeAttr( "disabled" );
            },
        },

        mounted()
        {
            axios
            .post(
                `${this.url}/easy-log`, {
                    route : this.$route.path,
                    code  : 200,
                },
            );

            if ( this.edLogin )
            {
                this.next();

                if ( this.edUserSign == true )
                {
                    this.btnFinishText  = "Finalizar c/ assinatura";
                    this.btnFinishClass = "btn-success";
                    this.btnAllowFinish = true;
                }
                else
                {
                    this.btnFinishText  = "INATIVO";
                    this.btnFinishClass = "btn-danger disabled";
                    this.btnAllowFinish = false;
                }

                if ( this.tokenCpf &&
                     this.tokenCpf.length > 0 &&
                     this.$route.params.o !== undefined )
                {
                    this.isRedirectFromAgenda = true;
                    this.scpf                 = this.tokenCpf.replace( /(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4" );

                    this.consultPatient();
                }

                this.$router
                .push(
                    { name: "receitadigital" }
                )
                .catch( err =>
                {
                    console.log( `${err.name}: ${err.message}` );

                    if ( err.name !== "NavigationDuplicated" && !err.message.includes( "Avoided redundant navigation to current location" ) )
                        logError( err );
                } );
            }
        },

        computed:
        {
            today() { return new Date().format(); },
        }
    }
</script>


<style lang="scss" >
    #app .ed_custom .vuecal__event {
        background-color: #F4FBFC;
    }
    .vuecal__flex .vuecal__weekdays-headings {
        padding-right: 0 !important;
    }

    #details-modal .modal-body {
        padding-top: 0;
    }
    .fs-normal {
        font-weight: initial;
        cursor: pointer;
    }
    .medicList i {
        cursor: pointer;
    }

    .alert-warning-ed {
        color: #857b26;
        background-color: #fffbdb;
        border-color: #fffacc;
    }
</style>
