<template>
    <div id="form" >
        <div id="dados-pessoais" class="card mt-2rem" >
            <div class="card-header" >
                <h4 class="ft-1rem mb-0" >Dados pessoais</h4>
            </div>
            <div class="card-body" >
                <div class="form-row" >
                    <div class="form-group col-md-4 col-12" >
                        <label for="snome" >Nome</label>
                        <input id="snome" class="form-control" type="text" maxlength="61" disabled
                            :value="snome"
                            @input="$emit('updatesnome', $event.target.value)"
                        />
                    </div>
                    <div class="form-group col-md-4 col-12" >
                        <label for="ssbrnome" >Sobrenome</label>
                        <input id="ssbrnome" class="form-control" type="text" maxlength="30" disabled
                            :value="ssbrnome"
                            @input="$emit('updatessbrnome', $event.target.value)"
                        />
                    </div>

                    <div class="form-group required col-lg-4 col-12" >
                        <label for="scpf" >CPF</label>
                        <input id="scpf" class="form-control" type="text" v-mask="'###.###.###-##'" disabled
                            :value="scpf"
                            @input="$emit('updatescpf', $event.target.value)"
                        />
                    </div>

                    <div class="form-group col-lg-2 col-md-6 col-12" >
                        <label for="dt_datnas" >Data nascimento</label>
                        <input id="dt_datnas" class="form-control" type="text" v-mask="'##/##/####'" required
                            :value="dt_datnas"
                            @input="$emit('updatedt_datnas', $event.target.value)"
                        />
                    </div>

                    <div class="form-group col-lg-2 col-md-6 col-12" >
                        <label for="srg" >RG</label>
                        <input id="srg" class="form-control" type="text" required
                            :value="srg"
                            @input="$emit('updatesrg', $event.target.value)"
                        />
                    </div>

                    <div class="form-group col-lg-2 col-12" >
                        <label for="nsexo" >Gênero</label>
                        <select id="nsexo" class="form-control" type="text" required
                            @change="$emit('updatensexo', $event.target.value)"
                        >
                            <option value="1" :selected="nsexo == 1" >Masculino</option>
                            <option value="2" :selected="nsexo == 2" >Feminino</option>
                        </select>
                    </div>

                    <div class="form-group col-lg-3 col-12" >
                        <label for="sprof" >Profissão</label>
                        <input type="text" class="form-control" maxlength="40" required
                            :value="sprof"
                            @input="$emit('updatesprof', $event.target.value)"
                        />
                    </div>

                    <div class="form-group col-md-3 col-12" >
                        <label for="nestciv" >Estado civil</label>
                        <select id="nestciv" class="form-control"
                            @change="$emit('updatenestciv', $event.target.value)"
                        >
                            <option v-for="(item) in estadocivil" :key="item['ID_ESTCIV']" :value="item['ID_ESTCIV']" :selected="nestciv == item['ID_ESTCIV']" >
                                {{ item["NM_ESTCIV"] }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-lg-8 col-12" >
                        <label for="nomres" >Responsável (apenas se não for o próprio paciente)</label>
                        <input id="nomres" class="form-control" type="text" maxlength="61" :required="menor"
                            :value="nomres"
                            @input="$emit('updatenomres', $event.target.value)"
                        />
                    </div>

                    <div
                        class="form-group required col-lg-4 col-12" >
                        <label for="scpf" >CPF do responsável</label>
                        <input id="scpfres" class="form-control" type="text" v-mask="'###.###.###-##'" :required="menor"
                            :value="scpfres"
                            @input="$emit('updatescpfres', $event.target.value)"
                        />
                    </div>

                    <div class="form-group col-md-3 col-12" >
                        <label for="sfone1" >Telefone 1</label>
                        <input id="sfone1" class="form-control" type="text" v-mask="['(##) ####-####', '(##) #####-####']" maxlength="20" required
                            :value="sfone1"
                            @input="$emit('updatesfone1', $event.target.value)"
                        />
                    </div>
                    <div class="form-group col-md-3 col-12" >
                        <label for="ntpfone1" >Tipo Telefone 1</label>
                        <select id="ntpfone1" class="form-control"
                            @change="$emit('updatentpfone1', $event.target.value)"
                        >
                            <option value="1" :selected="ntpfone1 == 1" >Residencial</option>
                            <option value="2" :selected="ntpfone1 == 2" >Comercial</option>
                            <option value="3" :selected="ntpfone1 == 3" >Fax</option>
                            <option value="4" :selected="ntpfone1 == 4" >Celular</option>
                            <option value="5" :selected="ntpfone1 == 5" >Recado</option>
                        </select>
                    </div>

                    <div class="form-group col-md-3 col-12" >
                        <label for="sfone2" >Telefone 2</label>
                        <input id="sfone2" class="form-control" type="text" maxlength="20" v-mask="['(##) ####-####', '(##) #####-####']"
                            :value="sfone2"
                            @input="$emit('updatesfone2', $event.target.value)"
                        />
                    </div>
                    <div class="form-group col-md-3 col-12" >
                        <label for="ntpfone2" >Tipo Telefone 2</label>
                        <select id="ntpfone2" class="form-control"
                            @change="$emit('updatentpfone2', $event.target.value)"
                        >
                            <option value="1" :selected="ntpfone2 == 1" >Residencial</option>
                            <option value="2" :selected="ntpfone2 == 2" >Comercial</option>
                            <option value="3" :selected="ntpfone2 == 3" >Fax</option>
                            <option value="4" :selected="ntpfone2 == 4" >Celular</option>
                            <option value="5" :selected="ntpfone2 == 5" >Recado</option>
                        </select>
                    </div>

                    <div class="form-group col-md-3 col-12" >
                        <label for="sfone3" >Telefone 3</label>
                        <input id="sfone3" class="form-control" type="text" maxlength="20" v-mask="['(##) ####-####', '(##) #####-####']"
                            :value="sfone3"
                            @input="$emit('updatesfone3', $event.target.value)"
                        />
                    </div>
                    <div class="form-group col-md-3 col-12" >
                        <label for="ntpfone3" >Tipo Telefone 3</label>
                        <select id="ntpfone3" class="form-control"
                            @change="$emit('updatentpfone3', $event.target.value)"
                        >
                            <option value="1" :selected="ntpfone3 == 1" >Residencial</option>
                            <option value="2" :selected="ntpfone3 == 2" >Comercial</option>
                            <option value="3" :selected="ntpfone3 == 3" >Fax</option>
                            <option value="4" :selected="ntpfone3 == 4" >Celular</option>
                            <option value="5" :selected="ntpfone3 == 5" >Recado</option>
                        </select>
                    </div>

                    <div class="form-group col-lg-6 col-md-10 col-12 mb-lg-0" >
                        <label for="semail" >E-mail</label>
                        <input id="semail" class="form-control" type="email" maxlength="50" required
                            :value="semail"
                            @input="$emit('updatesemail', $event.target.value)"
                        />
                    </div>

                    <div class="form-group col-md-3 col-12 mb-md-0" >
                        <label for="ntpind" >Como conheceu?</label>
                        <select id="ntpind" class="form-control"
                            @change="$emit('updatentpind', $event.target.value)"
                        >
                            <option v-for="(item) in tiposdeindicacao" :key="item['ID_STATUS']" :value="item['ID_STATUS']" :selected="ntpind == item['ID_STATUS']" >
                                {{ item["NM_STATUS"] }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-3 col-12 mb-0" >
                        <label for="sindpor" >Quem indicou?</label>
                        <input id="sindpor" class="form-control" type="text" placeholder="Nome de quem indicou" maxlength="60"
                            :value="sindpor"
                            @input="$emit('updatesindpor', $event.target.value)"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div id="endereco" class="card mt-2rem" >
            <div class="card-header" >
                <h4 class="ft-1rem mb-0" >Endereço</h4>
            </div>
            <div class="card-body" >
                <div class="form-row" >
                    <div class="form-group col-md-2 col-12" >
                        <label for="scep" >CEP</label>
                        <input id="scep" class="form-control" type="text" v-mask="'#####-###'" required
                            :value="scep"
                            @change="$emit('updatescep', $event.target.value)"
                        />
                    </div>

                    <div class="form-group col-md-2 col-12" >
                        <label for="estado" >Estado</label>
                        <select id="suf" class="form-control" name="estado"
                            @change="$emit('updatesuf', $event.target.value)"
                        >
                            <option v-for="item in listaDeEstados" :key="item.nome" :value="item.sigla" :selected="suf == item.sigla" >
                                {{ item.sigla }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-4 col-12" >
                        <label for="scid" >Cidade</label>
                        <select id="scid" class="form-control" name="cidade" required
                            @change="$emit('updatescid', $event.target.value)"
                        >
                            <option v-for="(item, index) in listaDeCidades" :key="index" :value="item" :selected="scid == item" >
                                {{ item }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-4 col-12" >
                        <label for="sbai" >Bairro</label>
                        <input id="sbai" class="form-control" type="text" maxlength="20" required
                            :value="sbai"
                            @input="$emit('updatesbai', $event.target.value)"
                        />
                    </div>

                    <div class="form-group col-md-8 col-12 mb-md-0" >
                        <label for="send" >Endereço com número</label>
                        <input id="send" class="form-control" type="text" maxlength="40" required
                            :value="send"
                            @input="$emit('updatesend', $event.target.value)"
                        />
                    </div>

                    <div class="form-group col-md-4 col-12 mb-0" >
                        <label for="scomplem" >Complemento</label>
                        <input id="scomplem" class="form-control" type="text" maxlength="10"
                            :value="scomplem"
                            @input="$emit('updatescomplem', $event.target.value)"
                        />
                    </div>

                </div>
            </div>
        </div>

        <div id="convenio" class="card mt-2rem" >
            <div class="card-header" >
                <h4 class="ft-1rem mb-0" >Convênio</h4>
            </div>
            <div class="card-body" >
                <div class="form-row" >
                    <div class="form-group col-md-4 col-12 mb-md-0" >
                        <label for="nconv" >Convênio</label>
                        <select id="nconv" class="form-control" name="nconv"
                            @change="$emit('updatenconv', $event.target.value)"
                        >
                            <option v-for="(item) in listaconvenios" :key="item['ID_CONVENIO']" :value="item['ID_CONVENIO']" :selected="nconv == item['ID_CONVENIO']" >
                                {{ item["NM_CONVENIO"] }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-4 col-12 mb-md-0" >
                        <label for="scart" >Número da carteirinha</label>
                        <input id="scart" class="form-control" type="text" :disabled="nconv == 1"
                            :value="scart"
                            @input="$emit('updatescart', $event.target.value)"
                        />
                    </div>
                    <div class="form-group col-md-4 col-12 mb-0" >
                        <label for="dt_datval" >Data de validade</label>
                        <input id="dt_datval" class="form-control" type="text" v-mask="'##/##/####'" :disabled="nconv == 1"
                            :value="dt_datval"
                            @input="$emit('updatedt_datval', $event.target.value)"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div id="photo" class="card mt-2rem" >
            <div class="card-header" >
                <h4 class="ft-1rem mb-0" >Foto</h4>
            </div>
            <div class="card-body" >
                <Camera ref="vueCamFile" />
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import Camera from "../../Camera";

    export default {
        props:
        {
            url: { type: String, required: true },
            snome:     "",
            ssbrnome:  "",
            dt_datnas: "",
            nsexo:     "",
            ntpfone1:  "",
            ntpfone2:  "",
            ntpfone3:  "",
            sfone1:    "",
            sfone2:    "",
            sfone3:    "",
            scpf:      "",
            srg:       "",
            send:      "",
            scomplem:  "",
            sbai:      "",
            scid:      "",
            suf:       "",
            scep:      "",
            semail:    "",
            sprof:     "",
            nconv:     null,
            scart:     "",
            dt_datval: "",
            ntpind:    "",
            sindpor:   "",
            nestciv:   "",
            nomres:    "",
            scpfres:   "",
            slgpd:     "",
            nropac:    "",

            listaconvenios:   [],
            tiposdeindicacao: [],
            estadocivil:      [],
            listaDeEstados:   [],
            listaDeCidades:   [],
            cepExistente:     null,
        },

        components:
        {
            Camera,
        },

        computed:
        {
            menor()
            {
                if ( moment().diff( moment( this.dt_datnas, "DD/MM/YYYY" ), "years", true ) < 18 )
                    return true;
            }
        },
    }
</script>

<style scoped >

</style>
