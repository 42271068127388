<template>

    <div class="container-fluid" v-if="token == false" >
        <div class="row justify-content-center" >
            <div class="col-12 col-md-8" >

                <div class="card mt-2rem" >
                    <div class="card-header" >
                        <h4 class="ft-1rem mb-0" >Gerar link de cadastro para o paciente</h4>
                    </div>

                    <div class="card-body" >
                        <div class="form-group row " >
                            <label for="cpf" class="col-md-4 col-form-label text-md-right" >CPF</label>
                            <div class="col-md-6" >
                                <input id="cpf" class="form-control" type="text" name="cpf" placeholder="ex: 000.000.000-00"
                                    v-model="scpf" v-mask="'###.###.###-##'" v-on:change="recuperarPaciente" required autofocus
                                >
                            </div>
                        </div>
                        <!-- <div class="form-row" > -->
                            <div class="form-group row mb-0" >
                                <label for="formType" class="col-md-4 col-form-label text-md-right" >Selecione o tipo do formulário:</label>
                                <div class="col-md-6" >
                                    <select id="formType" class="form-control" name="formType" v-model="formType" v-on:change="recuperarPaciente" required >
                                        <option value="anamnese" >Apenas anamnese</option>
                                        <option value="cadastro" >Apenas cadastro</option>
                                        <option value="completo" >Formulário completo</option>
                                    </select>
                                </div>
                            </div>
                        <!-- </div> -->

                        <div class="form-group row mb-0" >
                            <div id="loadMod" class="col-md-6 offset-md-4 d-none" >
                                <div class="alert alert-info mb-0 mt-3" >
                                    <div class="d-flex align-items-center" >
                                        <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                            <span class="sr-only" >Loading...</span>
                                        </div>
                                        <span>Consultando cadastro...</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 offset-md-4" v-if="cpfExistente == false && cpfConsultado == true && consultando == false" key="fail" >
                                <div class="alert alert-warning mb-0 mt-3" >
                                    <i class="fas fa-exclamation-circle" ></i> CPF não encontrado.
                                </div>
                                <button class="btn btn-primary btn-block mx-auto mt-3" v-on:click="resetProps" >Limpar</button>
                            </div>

                            <div class="col-md-6 offset-md-4" v-if="cpfExistente == true" key="success" >
                                <div class="alert alert-success mb-0 mt-3" >
                                    <i class="fas fa-check-circle" ></i> Tudo certo! Clique em Copiar para copiar o link.
                                </div>
                                <button class="btn btn-secondary btn-block mx-auto mt-3" v-on:click="resetProps" >Gerar novo link</button>
                            </div>
                        </div>
                    </div>

                    <div class="card-footer" v-if="cpfValido" >
                        <div class="row mb-0" >
                            <div class="col-12 col-md-8 col-lg-9 pr-md-2" >
                                <textarea class="form-control" name="link" rows="7" v-model="link" required disabled ></textarea>
                            </div>
                            <div class="col-12 col-md-4 col-lg-3 pl-md-2 mt-3 mt-md-0" >
                                <button class="btn btn-primary btn-block" v-clipboard:copy="link" >Copiar</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>

</template>

<script>
    import axios from "axios";

    export default {
        data()
        {
            return {
                headers       : { headers : { "Content-Type" : "application/json", Accept : "*" } },

                consultando   : null,
                cpfExistente  : null,
                cpfConsultado : false,
                scpf          : "",
                link          : "",
                formType      : "",
            };
        },
        props:
        {
            url: { type: String, required: true },
            token: { type: Boolean, default: false },
            expAccess: { type: String, required: false },
            expRegister: { type: String, required: false },
			clinic: Object,
        },

        beforeMount()
        {
            if ( this.token )
                window.location.href = "nao_autorizado";
        },

        mounted()
        {
            axios
            .post(
                `${this.url}/easy-log`,
                {
                    route : this.$route.path,
                    code  : 200,
                },
            );
        },

        methods:
        {
            recuperarPaciente()
            {
                if ( this.formType != "" && this.scpf != "" )
                {
                    let cpfTratado = this.scpf.replace( /\D/g, "" );

                    if ( this.scpf.length == 14 )
                    {
                        $( "#loadMod" ).removeClass( "d-none" );
                        this.consultando = true;

                        axios
                        .get( `${this.url}/clinica/paciente/${cpfTratado}` )
                        .then( response =>
                        {
                            this.cpfConsultado = true;

                            let data           = response.data;

                            if ( data.nropac &&
                                !( data.sanot.length   === 0 ) &&
                                !( data.nconv.length   === 0 ) &&
                                !( data.ntpind.length  === 0 ) &&
                                !( data.nestciv.length === 0 ) )
                            {
                                this.cpfExistente = true;
                            }
                            else
                            {
                                this.cpfExistente = false;
                            }

                            this.consultando = false;

                            $( "#loadMod" ).addClass( "d-none" );

                            axios
                            .post(
                                `${this.url}/gerar/link/paciente`,
                                {
                                    cpf     : cpfTratado,
                                    type    : this.formType,
                                },
                                this.headers
                            )
                            .then( response =>
                            {
                                this.link  = this.clinic.nome;
                                this.link += "\n\nClique no link abaixo para acessar o preenchimento de sua ficha cadastral.";
                                this.link += "\n*Atenção! O link é válido por " + this.expAccess + " horas após o primeiro acesso, e validade máxima de " + this.expRegister + " horas.*\n\n";
                                this.link += response.data.link;
                            } )
                            .catch( err =>
                            {
                                console.log( `linkPatient(), ${err.name}: ${err.message}` );

                                this.cpfExistente  = false;
                                this.consultando   = false;
                                this.cpfConsultado = true;

                                $( "#loadMod" ).addClass( "d-none" );
                            } );
                        } )
                        .catch( err =>
                        {
                            console.log( `retrievePatient(), ${err.name}: ${err.message}` );

                            this.cpfExistente  = false;
                            this.consultando   = false;
                            this.cpfConsultado = true;

                            $( "#loadMod" ).addClass( "d-none" );
                        } );

                        this.consultando = false;
                    }
                }
            },
            resetProps()
            {
                this.link          = "";
                this.scpf          = "";
                this.formType      = "";
                this.consultando   = false;
                this.cpfExistente  = false;
                this.cpfConsultado = false;
            },
        },

        computed:
        {
            cpfValido: function()
            {
                return this.cpfExistente;
            },
        },
    }
</script>

<style scoped >
</style>
