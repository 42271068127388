<template>

    <div id="lgpd" class="card mt-2rem" >
        <div class="card-header" >
            <h4 class="ft-1rem mb-0" >Lei Geral de Proteção de Dados (LGPD)</h4>
        </div>
        <div class="card-body" >
            <div class="form-row" >
                <div class="form-group col-12 mb-0" >
                    <label for="slgpd" >Por favor leia com atenção e assine caso concorde com os termos abaixo:</label>
                    <textarea class="form-control" name="slgpd" rows="20" v-model="slgpd" required disabled ></textarea>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        props:
        {
            slgpd: [],
        },
    }
</script>

<style scoped ></style>
