<template>

    <section>
        <header class="d-flex align-items-center justify-content-center mt-2rem" style="font-family: 'Arial', sans-serif !important; font-size: 20px;" >
            <b>{{ this.clinic.nome }}</b>
        </header>

        <div class="form container-fluid" >
            <div class="row justify-content-center" >
                <!-- FASE 0 -->
                <div class="col-12 col-md-8 col-lg-4" v-if="fase === 0" >
                    <div class="card mt-2rem" >
                        <div class="card-header" >
                            <h4 class="ft-1rem mb-0" >Entrar com seu login do EasyDental</h4>
                        </div>
                        <div class="card-body" >
                            <img :src="'/img/login.png'" class="img-fluid mx-auto my-4 d-block img-login" style="height: 200px;" alt="Entrar com seu login do EasyDental" />

                            <div class="form-row" >
                                <div class="form-group required col-12" >
                                    <label for="login" >Login</label>
                                    <input v-model="login" id="login" type="text" class="form-control" required autofocus
                                        autocomplete="ed-login" v-bind:disabled="consultingUser" >
                                </div>
                            </div>
                            <div class="form-row" >
                                <div class="form-group requires col-12 mb-0" >
                                    <label for="passw" >Senha</label>
                                    <input v-model="passw" id="passw" type="password" class="form-control" required
                                        autocomplete="ed-password" v-bind:disabled="consultingUser" >
                                </div>
                            </div>

                            <div class="carregamento" v-if="consultingUser" >
                                <div class="alert alert-info mt-4 mb-0" >
                                    <div class="d-flex align-items-center" >
                                        <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                            <span class="sr-only" >Loading...</span>
                                        </div>
                                        <span>Consultando cadastro...</span>
                                    </div>
                                </div>
                            </div>

                            <div class="alert alert-warning mt-4 mb-0" v-if="existingUser == false" >
                                <i class="fas fa-exclamation-circle" ></i>
                                {{ this.errorMsg ?? "Ops! Ocorreu um erro na consulta dos dados." }}
                            </div>

                            <div class="alert alert-success mt-4 mb-0" v-if="existingUser == true" >
                                <i class="fas fa-check-circle" ></i>
                                Tudo certo!
                            </div>
                        </div>

                        <div class="card-footer" v-if="existingUser == null" >
                            <div class="float-right" >
                                <button class="btn btn-info" v-on:click="loginUser" v-bind:disabled="consultingUser" >Entrar</button>
                            </div>
                        </div>

                        <div class="card-footer" v-if="existingUser == false" >
                            <div class="float-right" >
                                <button class="btn btn-success" v-on:click="start" >Recarregar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- FASE 1 -->
                <div class="col-12" v-if="fase === 1" >
                    <div class="card mt-2rem" >
                        <div class="card-header" >
                            <div class="float-left d-flex justify-content-start" >
                                <h4 class="ft-1rem mb-0" >Agenda</h4>

                                <div class="btn-group btn-group-toggle pl-2" data-toggle="buttons" >
                                    <label class="btn btn-sm btn-secondary active" >
                                        <input type="radio" name="agendaOptions" autocomplete="off" value="0"
                                            v-model="agendaOptions" id="ao_0" v-bind:disabled="consultingUser" >
                                        Individual
                                    </label>
                                    <label class="btn btn-sm btn-secondary" >
                                        <input type="radio" name="agendaOptions" autocomplete="off" value="1"
                                            v-model="agendaOptions" id="ao_1" v-bind:disabled="consultingUser" >
                                        Todas
                                    </label>
                                </div>
                            </div>

                            <div class="float-right" >
                                <!-- <span class="my-auto" >{{ ( this.edLogin ) ? this.edLogin : login }}</span> | <button class="btn btn-link btn-sm" v-on:click="logoutUser" >Sair</button> -->
                                <span class="my-auto" >{{ this.edLogin }}</span> | <button class="btn btn-link btn-sm p-0" v-on:click="logoutUser" >Sair</button>
                            </div>
                        </div>

                        <div class="card-body" >
                            <grid-loader class="mx-auto" color="#37D5F2"
                                v-if="!start_time && !end_time && !events && !errorSchdl && !errorApptt"
                            ></grid-loader>
                            <grid-loader class="mx-auto mb-3" color="#37D5F2"
                                v-if="showGridLoader == true"
                            ></grid-loader>

                            <div class="row" >
                                <div class="col-12 d-flex justify-content-center" >
                                    <button class="btn btn-info mb-3" onClick="window.location.reload();" v-if="errorSchdl || errorApptt" >
                                        Recarregar
                                    </button>
                                </div>
                                <div class="col-12 d-flex justify-content-center" >
                                    <div class="alert alert-warning text-center" v-if="errorSchdl" >
                                        <i class="fas fa-exclamation-circle" ></i>
                                        {{ this.errorSchdl ?? "Ops! Ocorreu um erro na consulta dos dados. Tente recarregar a pádina." }}
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-center" >
                                    <div class="alert alert-warning text-center" v-if="errorApptt" >
                                        <i class="fas fa-exclamation-circle" ></i>
                                        {{ this.errorApptt ?? "Ops! Ocorreu um erro na consulta dos dados. Tente recarregar a pádina." }}
                                    </div>
                                </div>
                            </div>

                            <div v-if="agendaOptions == 1" class="mb-3" >
                                <div class="form-check" v-for="item in splitDays" v-bind:key="item.id" >
                                    <input class="form-check-input" type="checkbox" v-bind:value="item.id" v-bind:id="item.id" v-on:click="item.hide = !item.hide" >
                                    <label class="form-check-label" v-bind:for="item.id" >
                                        Ocultar {{ item.label }}
                                    </label>
                                </div>
                            </div>

                            <vue-cal
                                v-if="start_time && end_time && !errorGlobal"

                                small
                                locale = "pt-br"
                                class  = "vuecal--blue-theme ed_custom"
                                style  = "height: 600px"

                                :disable-views   = "[ 'years', 'year', 'month' ]"

                                :time-from       = "start_time"
                                :time-to         = "end_time"
                                :time-step       = "30"
                                :hide-weekdays   = "[ 7 ]"

                                :events          = "events"
                                :split-days      = "splitDays"
                                :on-event-click  = "onEventClick"
                                :min-cell-width  = "75"
                                :min-split-width = "100"
                                :watch-real-time = "true"
                                :editable-events = "{ title:false, drag:false, resize:false, delete:false, create:false }"

                                :active-view.sync      = "activeView"
                                :selected-date.sync    = "selectedDate"

                                :cell-click-hold       = "false"
                                :drag-to-create-event  = "false"

                                :click-to-navigate     = "false"
                                :dbl-click-to-navigate = "false"

                                :sticky-split-labels   = "true"

                                @ready                 = "loginUser || getSchedules"
                                @view-change           = "viewChange( $event )"
                            >
                                <template #title="{ view }" >
                                    <span      v-if="view.id === 'week'" >{{ view.startDate.format( 'MMMM'   ) }}</span>
                                    <span v-else-if="view.id === 'day'"  >{{ view.startDate.format( 'dddd D' ) }}</span>
                                </template>
                            </vue-cal>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="details-modal" size="lg"
            :title="selectedEvent.title + ' ' + (selectedEvent.start && selectedEvent.start.format('DD/MM/YYYY'))"
            v-on:show="showModal(
                selectedEvent.hasCpf,
                [
                    selectedEvent.patientId,
                    selectedEvent.alertId,
                    selectedEvent.statusId,
                    selectedEvent.linkFinId,
                    selectedEvent.linkSigId,
                    selectedEvent.linkPrescId
                ],
                selectedEvent.status_id
            )"
            v-on:hide="hideModal( [
                selectedEvent.patientId,
                selectedEvent.alertId,
                selectedEvent.statusId,
                selectedEvent.linkFinId,
                selectedEvent.linkSigId,
                selectedEvent.linkPrescId
            ] )"
            no-close-on-backdrop
            hide-footer
            centered
            no-fade
        >
            <div class="row" >
                <div class="col-12" >
                    <p v-html="selectedEvent.contentFull" ></p>
                </div>

                <div class="col-12 col-sm-4" >
                    <p class="mb-0" ><strong>Início</strong>: {{ selectedEvent.start && selectedEvent.start.formatTime() }}</p>
                    <p class="mb-0" ><strong>Término</strong>: {{ selectedEvent.end && selectedEvent.end.formatTime() }}</p>
                </div>

                <div id="icons" class="col-12 col-sm-8 d-flex
                    justify-content-center justify-content-sm-end
                    align-items-center align-items-sm-end
                    mt-3 mt-sm-0 d-flex text-white flex-wrap"
                    v-if="selectedEvent.isTypePatient === true && selectedEvent.isAll === false"
                >
                    <a class="btn btn-default disabled" role="button"
                        title="Histórico clínico"
                        v-bind:id="selectedEvent.patientId"
                        v-on:click="showHistoricalFunc( selectedEvent.nropac )"
                        v-if="selectedEvent.nropac !== null"
                    >
                        <i class="fas fa-file-medical fa-lg" ></i>
                    </a>

                    <a class="btn btn-warning disabled" role="button"
                        title="Alerta de Anamnese"
                        v-bind:id="selectedEvent.alertId"
                        v-on:click="showAlertAnamneseFunc( selectedEvent.nropac )"
                        v-if="selectedEvent.nropac !== null"
                    >
                        <i class="fas fa-exclamation-triangle fa-lg" ></i>
                    </a>

                    <a class="btn btn-primary disabled" role="button"
                        title="Mudar status"
                        v-bind:id="selectedEvent.statusId"
                        v-on:click="showStatusAgendaFunc()"
                        v-if="this.statusAgenda !== null"
                    >
                        <i class="fas fa-sync-alt fa-lg" ></i>
                    </a>

                    <a class="btn btn-success disabled" role="button" target="_blank"
                        title="Finalizar procedimento"
                        v-bind:id="selectedEvent.linkFinId"
                        v-bind:href="this.url + '/' + selectedEvent.linkFinalize" >
                        <i class="fas fa-check fa-lg" ></i>
                    </a>

                    <a class="btn btn-info disabled" role="button" target="_blank"
                        title="Assinar procedimento(s)"
                        v-bind:id="selectedEvent.linkSigId"
                        v-bind:href="this.url + '/' + selectedEvent.linkSignature" >
                        <i class="fas fa-file-signature fa-lg" ></i>
                    </a>

                    <a class="btn btn-danger disabled" role="button" target="_blank"
                        title="Receita digital"
                        v-bind:id="selectedEvent.linkPrescId"
                        v-bind:href="this.url + '/' + selectedEvent.linkPrescript" >
                        <i class="fas fa-notes-medical fa-lg" ></i>
                    </a>
                </div>

                <div class="col-12 mt-2" v-if="selectedEvent.isAll === false && selectedEvent.isTypePatient === true && ( historical !== null || histLoading === true )" v-show="showHistorical" >
                    <p class="h3 mt-2" >Histórico clínico</p>
                    <grid-loader class="mx-auto mb-3" color="#37D5F2"
                        v-if="histLoading === true"
                    ></grid-loader>

                    <div class="table-responsive p-0 mb-0" >
                        <table class="table table-lg table-borderless table-striped table-head-fixed mb-0" >
                            <thead>
                                <tr>
                                    <th class="px-2 text-center item-dt"       >Data</th>
                                    <th class="px-2 text-center item-prest"    >Cirurgião Dentista</th>
                                    <th class="px-2 text-center item-regiao"   >Região</th>
                                    <th class="px-2 text-center item-historic" >Descrição dos procedimentos realizados</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( item, i ) in historical" v-bind:key="i" >
                                    <td class="px-2 text-center"         >{{ item.date     }}</td>
                                    <td class="px-2 text-center"         >{{ item.provider }}</td>
                                    <td class="px-2 text-center"         >{{ item.region   }}</td>
                                    <td class="px-2 text-left text-wrap" >{{ item.text     }}</td>
                                </tr>

                                <tr v-if="histError !== null && histLoading === false" >
                                    <td colspan="4" class="text-danger text-center h2" >
                                        {{ histError }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="col-12 mt-2" v-if="selectedEvent.isAll === false && selectedEvent.isTypePatient === true && ( alertAnamnese !== null || alertLoading === true )" v-show="showAlertAnamnese" >
                    <p class="h3 mt-2" >Alerta de Anamnese</p>
                    <grid-loader class="mx-auto mb-3" color="#37D5F2"
                        v-if="alertLoading === true"
                    ></grid-loader>

                    <div v-for="( item, i ) in alertAnamnese" v-bind:key="i" >
                        <p class="mb-0" >{{ item[0] }}<br>{{ item[1] }}</p>
                        <hr class="my-1" v-if="i != alertAnamnese.length - 1" />
                    </div>
                </div>

                <div class="col-12 mt-2" v-if="selectedEvent.isAll === false && selectedEvent.isTypePatient === true && this.statusAgenda !== null" v-show="showStatusAgenda" >
                    <p class="h3 mt-2 " >Alterar status</p>
                    <select v-model="newStatusAgenda" id="newStatusAgenda" class="form-control" type="text" required >
                        <option value="0" >Selecione o novo status</option>
                        <option v-for="item in statusAgenda" v-bind:key="item.status_id" v-bind:value="item.status_id" >
                            {{ item.status }}
                        </option>
                    </select>
                    <button class="btn btn-default btn-block mx-auto mt-3"
                        v-bind:disabled="this.newStatusAgenda === null || this.newStatusAgenda == 0 || this.disableBtnStatus === true"
                        v-on:click="saveNewstatusAgenda( selectedEvent.event_id )"
                    >Salvar</button>

                    <div class="alert alert-info mt-4 mb-0" v-if="this.disableBtnStatus === true" >
                        <div class="d-flex align-items-center" >
                            <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                <span class="sr-only" >Loading...</span>
                            </div>
                            <span>Salvando novo status...</span>
                        </div>
                    </div>

                    <div class="alert alert-warning mt-4 mb-0" v-if="this.errorStatusAgenda === true" >
                        <i class="fas fa-exclamation-circle" ></i>
                        {{ this.errorMsg ?? "Ops! Ocorreu um erro. Atualize a página e tente novamente." }}
                    </div>
                    <div class="alert alert-success mt-4 mb-0" v-if="this.successStatusAgenda === true" >
                        <i class="fas fa-check-circle" ></i>
                        Status alterado!
                    </div>
                </div>

            </div>
        </b-modal>
    </section>

</template>

<script>
    import axios          from "axios";
    import { GridLoader } from 'vue-spinner/dist/vue-spinner.min.js';
    import VueCal         from "vue-cal";
    import "../../../../node_modules/vue-cal/dist/vuecal.css";

    export default {
        data()
        {
            return {
                fase           :  0,
                login          : "",
                passw          : "",
                nprest         : 0,

                errorMsg       : null,
                errorSchdl     : null,
                errorApptt     : null,

                existingUser   : null,
                consultingUser : false,

                start_time     : null,
                end_time       : null,
                events         : [],
                splitDays      : [],
                selectedEvent  : {},
                agendaOptions  : 0,

                showGridLoader : false,

                activeView     : "week",
                selectedDate   : this.today,

                dateOfEventsLoaded : {
                    start : 0,
                    end   : 0,
                },

                errorGlobal         : false,

                showAlertAnamnese   : false,
                alertError          : null,
                alertAnamnese       : null,
                alertLoading        : false,
                alertNropac         : 0,

                showHistorical      : false,
                histError           : null,
                historical          : null,
                histLoading         : false,

                showStatusAgenda    : false,
                statusAgenda        : null,
                newStatusAgenda     : null,
                disableBtnStatus    : false,
                errorStatusAgenda   : false,
                successStatusAgenda : false,
            };
        },

        props:
        {
            user: Object,
            token: Boolean,
            clinic: Object,
            edLogin: String,
            tokenCpf: String,
            tokenTime: String,
            resetTokens: Function,
            url: { type: String, required: true },
        },

        components:
        {
            GridLoader,
            VueCal
        },

        methods:
        {
            start()
            {
                this.fase           = 0;
                this.login          = "";
                this.passw          = "";
                this.existingUser   = null;
                this.consultingUser = false;

                this.errorMsg       = null;
                this.errorSchdl     = null;
                this.errorApptt     = null;

                this.events             = [];
                this.dateOfEventsLoaded = this.getCurrentWeek;
            },
            next()
            {
                this.fase += 1;
            },

            loginUser()
            {
                if ( this.login.length == 0 || this.passw.length == 0 )
                {
                    this.login          = "";
                    this.passw          = "";
                    this.existingUser   = null;
                    this.consultingUser = false;
                }
                else
                {
                    this.consultingUser = true;

                    axios
                    .post(
                        `${this.url}/easydental/login`,
                        {
                            login: this.login,
                            passw: this.passw,
                            option: "agenda",
                        }
                    )
                    .then( response =>
                    {
                        this.processData( response.data );

                        if ( this.existingUser )
                            this.next();

                        this.dateOfEventsLoaded = this.getCurrentWeek;
                    } )
                    .catch( err =>
                    {
                        console.log( `login(), ${err.name}: ${err.message}` );

                        this.existingUser   = false;
                        this.consultingUser = false;
                    } );
                }
            },
            logoutUser()
            {
                axios
                .post( `${this.url}/easydental/logout` )
                .then( response =>
                {
                    this.existingUser   = false;
                    this.consultingUser = false;
                    this.$emit( "update:edLogin", "" );
                    this.start();
                } )
                .catch( err =>
                {
                    console.log( `logout(), ${err.name}: ${err.message}` );

                    this.existingUser   = false;
                    this.consultingUser = false;
                } );
            },

            getSchedules( start = null, end = null, all = false )
            {
                this.existingUser   = null;
                this.consultingUser = true;

                let data = {};

                if ( start && end )
                {
                    this.showGridLoader = true;
                    data = { start, end };
                }

                data.all = all;

                axios
                .post(
                    `${this.url}/easydental/schdl`,
                    data
                )
                .then( response =>
                {
                    this.processData( response.data );
                    this.showGridLoader = false;
                } )
                .catch( err =>
                {
                    console.log( `getSchedules(), ${err.name}: ${err.message}` );

                    this.existingUser   = false;
                    this.consultingUser = false;
                } );
            },

            onEventClick( event, e )
            {
                this.selectedEvent = event;
                this.$bvModal.show( "details-modal" );
                e.stopPropagation();
            },

            processData( data )
            {
                let user  = data.user;
                let schdl = data.schedule;
                let apptt = data.appointments;
                let prvdr = data.providers; // agenda em modo "Todos"

                this.statusAgenda = data.statusAgenda;

                if ( user != null && user.code == null)
                {
                    this.$emit( "update:edLogin", user.data.login );

                    if ( schdl != null && schdl.code == null )
                    {
                        this.start_time = schdl.data.start_time * 60;
                        this.end_time   = schdl.data.end_time   * 60;

                        if ( apptt != null && apptt.code === null )
                        {
                            let auxEvents = [];

                            apptt.data.forEach( ( el, idx ) => {

                                let linkFinalize  = null;
                                let linkSignature = null;
                                let linkPrescript = null;

                                let isTypePatient = false;

                                let content       = "";
                                let cssCls        = `cssTest_${ el.color.substring( 1 ) }`;
                                let style         = document.createElement( "style" );
                                style.type        = "text/css";
                                style.textContent = `.${ cssCls } { background-color: ${ el.color }50 !important; font-size: 12px; color: #000; }`;

                                document.getElementsByTagName( "head" )[ 0 ].appendChild( style );

                                if ( el.type != 2 )
                                {
                                    content       = ( el.phone )
                                                  ? `Celular: <a class="mx-1" href="https://wa.me/55${ el.phone }" target="_blank" >${ el.phone }</a><br>`
                                                  : 'Celular: <br>';

                                    let date      = el.start_date.split( " " )[0];
                                    linkFinalize  = `clinica/procedimentos/${ el.cpf }/${ date }`;
                                    linkSignature = `clinica/procedimentos/dia/${ el.cpf }`;
                                    linkPrescript = `clinica/receita/${ el.cpf }`;

                                    isTypePatient = true;
                                }

                                auxEvents.push( {
                                    isTypePatient : isTypePatient,            // paciente = 1, compromisso = 2
                                    isAll         : ( prvdr ) ? true : false, // agenda em modo "Todos"
                                    hasCpf        : ( el.cpf !== "" ),

                                    linkSigId     : "sig_1_" + idx,
                                    linkSignature : linkSignature,

                                    linkFinId     : "fin_2_" + idx,
                                    linkFinalize  : linkFinalize,

                                    linkPrescId   : "psc_6_" + idx,
                                    linkPrescript : linkPrescript,

                                    event_id      : el.event_id,

                                    statusId      : "stt_3_" + idx,
                                    status_id     : el.status_id,
                                    alertId       : "alt_4_" + idx,
                                    patientId     : "ptt_5_" + idx,
                                    nropac        : el.nropac,

                                    start         : el.start_date,
                                    end           : el.end_date,
                                    title         : el.name ? el.name : el.motive,
                                    contentFull   : content + `Motivo: ${el.motive}<br>Status: ${el.status}`,
                                    deletable     : false,
                                    resizable     : false,
                                    draggable     : false,
                                    class         : cssCls,
                                    split         : parseInt( el.nprest )
                                } );
                            } );

                            if ( this.events.length == 0 )
                            {
                                this.events = auxEvents;
                            }
                            else
                            {
                                auxEvents.forEach( el => {

                                    let event = this.events.find( e => e.event_id == el.event_id );

                                    if ( !event )
                                        this.events.push( el );
                                } );
                            }

                            if ( prvdr )
                            {
                                let auxProviders = [];

                                prvdr.forEach( el => {
                                    auxProviders.push( {
                                        id    : parseInt( el.nprest ),
                                        label : el.sprest,
                                        hide  : false,
                                    } );
                                } );

                                if ( this.splitDays.length > 0 )
                                {
                                    auxProviders.forEach( el => {
                                        let index = this.splitDays.findIndex( function( e, i )
                                        {
                                            if ( e.id == this )
                                                return true;
                                        }, el.id );

                                        if ( index == -1 )
                                            this.displayDays.push( el );
                                    } );
                                }
                                else
                                {
                                    this.splitDays = auxProviders;
                                }
                            }
                        }
                        else if ( apptt != null && apptt.code == 4002 )
                        {
                            this.events     = ( this.events.length > 0 ) ? this.events : [];
                        }
                        else
                        {
                            this.events     = ( this.events.length > 0 ) ? this.events : [];

                            // error Appointments
                            this.errorApptt = apptt.message;
                        }
                    }
                    else
                    {
                        // error Schedule
                        this.errorSchdl  = schdl.message;
                        this.errorGlobal = true;
                    }

                    this.existingUser   = true;
                    this.consultingUser = false;
                }
                else if ( user != null && user.code == 4000 )
                {
                    this.errorMsg       = user.message;
                    this.existingUser   = false;
                    this.consultingUser = false;
                }
                else
                {
                    this.existingUser   = false;
                    this.consultingUser = false;
                }
            },

            viewChange( event )
            {
                if ( event.view == "week" || event.view == "day" )
                {
                    let ed    = this.dateOfEventsLoaded;
                    let all   = this.agendaOptions == 1 ? true : false;

                    let start = event.startDate.format( "YYYY-MM-DD" );
                    let end   = event.endDate.format( "YYYY-MM-DD" );

                    if ( start >= ed.start && end <= ed.end )
                        return;

                    if ( start > ed.end   )
                        this.dateOfEventsLoaded.end   = end;

                    if ( end   < ed.start )
                        this.dateOfEventsLoaded.start = start;

                    this.getSchedules( start, end, all );
                }
            },

            showModal( hasCpf, ids, status_id )
            {
                const _ids_all = [ "sig_1_", "fin_2_", "stt_3_", "alt_4_", "ptt_5_", "psc_6_" ];
                const _ids_sa  = [ "stt_3_", "alt_4_" ];

                this.newStatusAgenda = status_id;

                setTimeout( () => {
                    ids.forEach( el => {
                        let substr = el.slice( 0, 6 );

                        if ( hasCpf && _ids_all.includes( substr ) )
                            $( `#${el}` ).removeClass( "disabled" );
                        else if ( _ids_sa.includes( substr ) )
                            $( `#${el}` ).removeClass( "disabled" );
                    } );
                }, 750 );
            },
            hideModal( ids )
            {
                ids.forEach( el => {
                    $( `#${el}` ).addClass( "disabled" );
                } );

                this.showHistorical    = false;
                this.histError         = null;
                this.historical        = null;
                this.histLoading       = false;

                this.showAlertAnamnese = false;
                this.showStatusAgenda  = false;
                this.disableBtnStatus  = false;
                this.newStatusAgenda   = null;
            },

            changeViewAgenda( val )
            {
                this.showGridLoader     = true;
                this.dateOfEventsLoaded = this.getCurrentWeek;

                if ( val == 1 )
                {
                    this.events     = [];
                    this.splitDays  = [];

                    this.getSchedules( this.dateOfEventsLoaded.start, this.dateOfEventsLoaded.end, true );
                    this.activeView = "day";
                }

                if ( val == 0 )
                {
                    this.events     = [];
                    this.splitDays  = [];

                    this.getSchedules( this.dateOfEventsLoaded.start, this.dateOfEventsLoaded.end );
                    this.activeView = "week";
                }
            },

            showHistoricalFunc( nropac )
            {
                this.showHistorical    = !this.showHistorical;
                this.showAlertAnamnese = false;
                this.showStatusAgenda  = false;

                if ( this.historical === null || this.histError !== null )
                {
                    this.histError   = null;
                    this.histLoading = false;

                    if ( this.showHistorical )
                    {
                        this.histLoading = true;

                        axios
                        .post(
                            `${this.url}/clinica/paciente/historico`, {
                            nropac: nropac,
                            option: "agenda",
                        } )
                        .then( response =>
                        {
                            this.histError   = null;
                            this.histLoading = false;
                            this.historical  = response.data;
                        } )
                        .catch( err =>
                        {
                            console.log( `retrieveHistory(), ${err.name}: ${err.message}` );
                            this.histError   = "Não foi possível carregar o histórico clínico.";
                            this.historical  = false;
                            this.histLoading = false;
                        } );
                    }
                }
            },
            showAlertAnamneseFunc( nropac )
            {
                this.showAlertAnamnese = !this.showAlertAnamnese;
                this.showHistorical    = false;
                this.showStatusAgenda  = false;

                if ( this.alertAnamnese === null || this.alertError !== null || this.alertNropac != nropac )
                {
                    this.alertAnamnese = null;
                    this.alertNropac   = nropac;

                    this.alertError    = null;
                    this.alertLoading  = false;

                    if ( this.showAlertAnamnese )
                    {
                        this.alertLoading = true;

                        axios
                        .post(
                            `${this.url}/easydental/alert`,
                            { nropac: nropac }
                        )
                        .then( response =>
                        {
                            this.alertError    = null;
                            this.alertLoading  = false;
                            this.alertAnamnese = response.data;
                        } )
                        .catch( err =>
                        {
                            console.log( `getAlerts(), ${err.name}: ${err.message}` );

                            this.alertError    = "Não foi possível carregar o Alerta de Anamnese.";
                            this.alertLoading  = false;
                            this.alertAnamnese = false;
                        } );
                    }
                }
            },
            showStatusAgendaFunc()
            {
                this.showStatusAgenda  = !this.showStatusAgenda;
                this.showHistorical    = false;
                this.showAlertAnamnese = false;
            },

            saveNewstatusAgenda( event_id )
            {
                this.errorMsg         = null;
                this.disableBtnStatus = true;

                axios
                .post(
                    `${this.url}/clinica/paciente/status`,
                    {
                        status_id : this.newStatusAgenda,
                        event_id  : event_id,
                    }
                )
                .then( response =>
                {
                    this.disableBtnStatus    = false;
                    this.successStatusAgenda = true;
                    this.$router.go();
                } )
                .catch( err =>
                {
                    console.log( `changeStatusAppointment(), ${err.name}: ${err.message}` );

                    this.disableBtnStatus  = false;
                    this.errorStatusAgenda = true;
                    this.errorMsg          = err.response.data.data;
                } );
            }
        },

        mounted()
        {
            axios
            .post(
                `${this.url}/easy-log`,
                {
                    route : this.$route.path,
                    code  : 200,
                },
            );

            if ( this.edLogin )
            {
                this.next();
                this.getSchedules();

                this.showGridLoader     = true;
                this.dateOfEventsLoaded = this.getCurrentWeek;
            }
        },

        computed:
        {
            today()
            {
                return new Date().format();
            },

            getCurrentWeek()
            {
                const today     = new Date();
                const first     = today.getDate() - today.getDay() + 1;
                const endWeek   = new Date( today.setDate( first + 6 ) );
                const startWeek = new Date( today.setDate( first     ) );

                return {
                    start : startWeek.format(),
                    end   : endWeek.format()
                };
            }
        },

        watch:
        {
            'agendaOptions': {
                handler: function( newVal, oldVal ) {
                    this.changeViewAgenda( newVal );
                }
            }
        }
    }
</script>


<style lang="scss" >
    #app .ed_custom .vuecal__event {
        background-color: #F4FBFC;
    }
    .vuecal__flex .vuecal__weekdays-headings {
        padding-right: 0 !important;
    }

    #details-modal .modal-body {
        padding-top: 0;
    }

    .fas.fa-check.fa-2x {
        color: #FFF;
    }

    .vuecal--week-view .day-split-header,
    .vuecal--day-view .day-split-header {
        font-size: 11px;
    }
    .vuecal__flex .vuecal__split-days-headers {
        min-height: 1.5rem;
    }

    #icons .fas {
        min-width: 20px !important;
        max-width: 20px !important;
    }

    @media ( max-width: 991px )
    {
        .modal.show .modal-dialog {
            max-width: calc(100% - 30px) !important;
        }
    }

    @media ( max-width: 419px )
    {
        #icons a {
            margin-left: .25rem;
            margin-right: .25rem;
            margin-bottom: .5rem;
        }
    }
</style>
