<template>

    <div id="assinatura" class="card mt-2rem" >
        <div class="card-header" >
            <h4 class="ft-1rem mb-0" >Assinatura</h4>
        </div>
        <div class="card-body" >
            <p>Para finalizar o cadastro, por favor, faça sua assinatura na área abaixo:</p>
            <div class="form-row" >
                <div class="form-group col-md-12 col-12 mb-0" >
                    <label for>Assinatura</label>

                    <VueSignaturePad id="canvasAssinatura" width="100%" height="300px" style="border: 1px solid #f0f0f0" ref="signaturePad" class="signaturePad" />

                    <div class="acoes mt-4" >
                        <button type="button" class="btn btn-outline-warning" @click="undo" >
                            <i class="fas fa-eraser" ></i> Apagar
                        </button>
                        <button type="button" class="btn btn-success" @click="save" >
                            Confirmar minha assinatura
                        </button>
                    </div>
                </div>
            </div>

            <p class="mt-3 mb-0" v-if="this.assinatura == ''" >Para finalizar o seu cadastro, faça a sua assinatura no quadro acima e clique em "Confirmar minha assinatura".</p>
        </div>
    </div>

</template>

<script>
    export default {
        data()
        {
            return {
                assinatura: "",
            }
        },

        methods:
        {
            undo()
            {
                this.$refs.signaturePad.clearSignature();
                this.assinatura = "";
                this.$emit( "saveSig", this.assinatura );
            },

            save()
            {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

                if ( !isEmpty )
                {
                    let canvas      = document.querySelector( "#canvasAssinatura canvas" );
                    this.assinatura = canvas.toDataURL();
                    this.$emit( "saveSig", this.assinatura );
                }
                else
                {
                    alert( "A assinatura está em branco." );
                }
            },
        }
    }
</script>

<style scoped ></style>
