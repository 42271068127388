<template>

    <div class="container" v-if="token == false" >
        <div class="row mt-2rem justify-content-center" >

            <!-- <div class="col-12 col-md-6 col-lg-4 mb-3" >
                <router-link to="/cadastro" >
                    <div class="small-box h-100 d-flex bg-white" >
                        <div class="m-auto text-center py-5 px-3" >
                            <h3 class="text-wrap mb-0" >
                                <i class="nav-icon fas fa-calendar" ></i> Cadastro
                            </h3>
                        </div>
                    </div>
                </router-link>
            </div> -->

            <div class="col-12 col-md-6 mb-4" v-if="this.user.role != '2'" >
                <router-link to="/cadastro" >
                    <div class="small-box h-100 d-flex bg-white" >
                        <div class="m-auto text-center py-5 px-3" >
                            <h3 class="text-wrap mb-0" >
                                <i class="nav-icon fas fa-clipboard-list" ></i> Cadastro
                            </h3>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-12 col-md-6 mb-4" v-if="this.user.role != '2'" >
                <router-link to="/gerar-link-paciente" >
                    <div class="small-box h-100 d-flex bg-white" >
                        <div class="m-auto text-center py-5 px-3" >
                            <h3 class="text-wrap mb-0" >
                                <i class="nav-icon fas fa-link" ></i> Gerar link paciente
                            </h3>
                        </div>
                    </div>
                </router-link>
            </div>

            <div class="col-12 col-md-6 mb-4" v-if="this.user.role != '2'" >
                <router-link to="/documento" >
                    <div class="small-box h-100 d-flex bg-white" >
                        <div class="m-auto text-center py-5 px-3" >
                            <h3 class="text-wrap mb-0" >
                                <i class="nav-icon fas fa-file-signature" ></i> Documentos
                            </h3>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-12 col-md-6 mb-4" v-if="this.user.role != '2'" >
                <router-link to="/gerar-link-documento" >
                    <div class="small-box h-100 d-flex bg-white" >
                        <div class="m-auto text-center py-5 px-3" >
                            <h3 class="text-wrap mb-0" >
                                <i class="nav-icon fas fa-link" ></i> Gerar link documentos
                            </h3>
                        </div>
                    </div>
                </router-link>
            </div>

            <div class="col-12 col-md-6 col-lg-4 mb-4" v-if="this.user.role != '2'" >
                <router-link to="/procedimentos-dia" >
                    <div class="small-box h-100 d-flex bg-white" >
                        <div class="m-auto text-center py-5 px-3" >
                            <h3 class="text-wrap mb-0" >
                                <i class="nav-icon fas fa-calendar-day" ></i> Procedimentos<br>realizados no dia
                            </h3>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4" v-if="this.user.role == '1'" >
                <router-link to="/procedimentos-periodo" >
                    <div class="small-box h-100 d-flex bg-white" >
                        <div class="m-auto text-center py-5 px-3" >
                            <h3 class="text-wrap mb-0" >
                                <i class="nav-icon fas fa-calendar-alt" ></i> Procedimentos<br>realizados no período
                            </h3>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-4" v-if="this.user.role != '2'" >
                <router-link to="/procedimentos-finalizar" >
                    <div class="small-box h-100 d-flex bg-white" >
                        <div class="m-auto text-center py-5 px-3" >
                            <h3 class="text-wrap mb-0" >
                                <i class="nav-icon fas fa-calendar-check" ></i> Finalizar<br>procedimentos
                            </h3>
                        </div>
                    </div>
                </router-link>
            </div>

            <div class="col-12 col-md-6 col-lg-4 mb-4" v-if="this.user.role != '2'" >
                <router-link to="/capturar-imagem" >
                    <div class="small-box h-100 d-flex bg-white" >
                        <div class="m-auto text-center py-5 px-3" >
                            <h3 class="text-wrap mb-0" >
                                <i class="nav-icon fas fa-camera" ></i> Capturar imagem
                            </h3>
                        </div>
                    </div>
                </router-link>
            </div>

            <div class="col-12 col-md-6 col-lg-4 mb-4" v-if="this.user.role == '1'" >
                <router-link to="/dashboard" >
                    <div class="small-box h-100 d-flex bg-white" >
                        <div class="m-auto text-center py-5 px-3" >
                            <h3 class="text-wrap mb-0" >
                                <i class="nav-icon fas fa-chart-line" ></i> Dashboard
                            </h3>
                        </div>
                    </div>
                </router-link>
            </div>

            <div class="col-12 col-md-6 col-lg-4 mb-4" >
                <router-link to="/agenda" >
                    <div class="small-box h-100 d-flex bg-white" >
                        <div class="m-auto text-center py-5 px-3" >
                            <h3 class="text-wrap mb-0" >
                                <i class="nav-icon fas fa-clock" ></i> Agenda
                            </h3>
                        </div>
                    </div>
                </router-link>
            </div>

            <div class="col-12 col-md-6 col-lg-4 mb-4" >
                <router-link to="/receita-digital" >
                    <div class="small-box h-100 d-flex bg-white" >
                        <div class="m-auto text-center py-5 px-3" >
                            <h3 class="text-wrap mb-0" >
                                <i class="nav-icon fas fa-notes-medical" ></i> Receita digital
                            </h3>
                        </div>
                    </div>
                </router-link>
            </div>

            <!-- <div class="col-12 col-md-6 col-lg-4 mb-4" >
                <router-link to="/estoque" >
                    <div class="small-box h-100 d-flex bg-white" >
                        <div class="m-auto text-center py-5 px-3" >
                            <h3 class="text-wrap mb-0" >
                                <i class="nav-icon fas fa-cube" ></i> Estoque
                            </h3>
                        </div>
                    </div>
                </router-link>
            </div> -->

        </div>
    </div>

</template>

<script>
    export default {
        props:
        {
            user: Object,
            token: {
                type: Boolean,
                default: false,
            },
        },

        beforeMount()
        {
            if ( this.token )
                window.location.href = "nao_autorizado";
        },
    }
</script>

<style scoped >
    .small-box {
        border-radius: 1.2rem;
        box-shadow: 0 5px 20px 0 rgb(69 67 96 / 5%);
    }
    .nav-icon {
        color: #32325d !important;
    }
</style>
