<template>

    <section>
        <header class="d-flex align-items-center justify-content-center mt-2rem" style="font-family: 'Arial', sans-serif !important; font-size: 20px;" >
            <b>{{ this.clinic.nome }}</b>
        </header>

        <div class="form container-fluid" >
            <div class="row justify-content-center" >
                <!-- FASE 0 -->
                <div class="col-12 col-md-8 col-lg-6 col-xl-4" v-if="fase === 0" >
                    <div class="card mt-2rem" >
                        <div class="card-header" >
                            <h4 class="ft-1rem mb-0" >Entrar com seu login do EasyDental</h4>
                        </div>
                        <div class="card-body" >
                            <img v-bind:src="'/img/login.png'" class="img-fluid mx-auto my-4 d-block img-login" style="height: 200px;" alt="Entrar com seu login do EasyDental" />

                            <div class="form-row" >
                                <div class="form-group required col-12" >
                                    <label for="login" >Login</label>
                                    <input v-model="login" id="login" type="text" class="form-control" required autofocus
                                        autocomplete="ed-login" v-bind:disabled="consultingUser" >
                                </div>
                            </div>
                            <div class="form-row" >
                                <div class="form-group requires col-12 mb-0" >
                                    <label for="passw" >Senha</label>
                                    <input v-model="passw" id="passw" type="password" class="form-control" required
                                        autocomplete="ed-password" v-bind:disabled="consultingUser" >
                                </div>
                            </div>

                            <div class="carregamento" v-if="consultingUser" >
                                <div class="alert alert-info mt-4 mb-0" >
                                    <div class="d-flex align-items-center" >
                                        <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                            <span class="sr-only" >Loading...</span>
                                        </div>
                                        <span>Consultando cadastro...</span>
                                    </div>
                                </div>
                            </div>

                            <div class="alert alert-warning mt-4 mb-0" v-if="existingUser == false" >
                                <i class="fas fa-exclamation-circle" ></i>
                                {{ this.errorMsg ?? "Ops! Ocorreu um erro na consulta dos dados." }}
                            </div>

                            <div class="alert alert-success mt-4 mb-0" v-if="existingUser == true" >
                                <i class="fas fa-check-circle" ></i>
                                Tudo certo!
                            </div>
                        </div>

                        <div class="card-footer" v-if="existingUser == null" >
                            <div class="float-right" >
                                <button class="btn btn-info" v-on:click="loginUser" v-bind:disabled="consultingUser" >Entrar</button>
                            </div>
                        </div>

                        <div class="card-footer" v-if="existingUser == false" >
                            <div class="float-right" >
                                <button class="btn btn-success" v-on:click="start" >Recarregar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- FASE 1 -->
                <div class="col-12 col-md-8 col-lg-6 col-xl-4" v-if="fase === 1" >
                    <div class="card mt-2rem" >

                        <div class="card-header" >
                            <h4 class="float-left ft-1rem mb-0" >Procedimentos</h4>
                            <div class="float-right" >
                                <span class="my-auto" >{{ this.edLogin }}</span> | <button class="btn btn-link btn-sm p-0" v-on:click="logoutUser" >Sair</button>
                            </div>
                        </div>

                        <div class="card-body" >
                            <p>Insira o CPF do paciente, para consultamos os procedimentos.</p>
                            <div class="form-row" >
                                <div class="form-group required col-md-12 col-12 mb-0" >
                                    <input v-model="scpf" id="scpf" class="form-control" type="text" placeholder="ex: 000.000.000-00"
                                        v-mask="'###.###.###-##'" required v-bind:disabled="consultingPatient || tokenCpf.length > 0" />
                                </div>
                            </div>

                            <div class="carregamento" v-if="consultingPatient" >
                                <div class="alert alert-info mt-4 mb-0" >
                                    <div class="d-flex align-items-center" >
                                        <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                            <span class="sr-only" >Loading...</span>
                                        </div>
                                        <span>Consultando cadastro...</span>
                                    </div>
                                </div>
                            </div>

                            <div class="alert alert-warning mt-4 mb-0" v-if="existingPatient == false" >
                                <i class="fas fa-exclamation-circle" ></i>
                                {{ this.errorMsg ?? "Ops! Ocorreu um erro na consulta dos dados." }}
                            </div>

                            <div class="alert alert-success mt-4 mb-0" v-if="existingPatient == true" >
                                <i class="fas fa-check-circle" ></i>
                                Tudo certo!
                            </div>
                        </div>

                        <div class="card-footer" v-if="existingPatient == null" >
                            <div class="float-right" >
                                <button class="btn btn-info" v-on:click="consultPatient" v-bind:disabled="consultingPatient" >Consultar</button>
                            </div>
                        </div>
                        <div class="card-footer" v-if="existingPatient == false" >
                            <div class="float-right" >
                                <button class="btn btn-success" v-on:click="refresh" >Recarregar</button>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- FASE 2 -->
                <div class="col-12 col-md-8 col-xl-6" v-if="fase === 2" >
                    <div class="card mt-2rem" >

                        <div class="card-header" >
                            <h4 class="float-left ft-1rem mb-0" >Procedimentos</h4>
                        </div>
                        <div class="card-body" >

                            <div class="form-group row" >
                                <div class="col-12" >
                                    <label for="dtFinal" >Data de finalização</label>
                                    <input id="dtFinal" class="form-control" type="text" v-mask="'##/##/####'" placeholder="dd/mm/aaaa"
                                        required v-model="dtFinal" />
                                </div>
                            </div>

                            <div class="form-group row" >
                                <label class="col-12" >Selecione os procedimentos que deseja finalizar:</label>

                                <div class="col-12 mb-2 d-flex align-items-center" v-for="( item ) in listProcs" v-bind:key="item[ 'idproc' ]" >
                                    <input class="my-auto" type="checkbox" v-bind:id="item[ 'idproc' ]" v-model="item.selected"
                                        v-on:change="changeSelectedProcs"
                                        v-on:click="removeError" >
                                    <label v-bind:for="item[ 'idproc' ]" class="fs-normal mb-0 ml-3" >
                                        {{ item[ 'nmproc' ] }} - {{ item[ 'regproc' ] }}
                                        <br>
                                        {{ item[ 'dttrat' ] }} - {{ item[ 'idtrat' ] }} - {{ item[ 'nmtab' ] }}
                                    </label>
                                </div>
                                <div class="col-12 mb-2 d-flex align-items-center" v-if="listProcs.length == 0" >
                                    <small>Paciente não tem procedimentos em aberto.</small>
                                </div>
                            </div>

                            <div class="form-group row mb-0" >
                                <div class="col-12" >
                                    <label for="textProc" >Informações adicionais</label>
                                    <textarea v-model="textProc" class="form-control" rows="3" id="textProc"
                                        v-on:click="removeError" ></textarea>
                                </div>
                            </div>

                            <div class="carregamento" v-if="sendingData" >
                                <div class="alert alert-info mt-4 mb-0" >
                                    <div class="d-flex align-items-center" >
                                        <div class="spinner-border spinner-border-sm mr-2" role="status" >
                                            <span class="sr-only" >Loading...</span>
                                        </div>
                                        <span>Enviando dados...</span>
                                    </div>
                                </div>
                            </div>

                            <div class="alert alert-warning mt-4 mb-0" v-if="dataSent == false" >
                                <i class="fas fa-exclamation-circle" ></i>
                                {{ this.errorMsg ?? "Ops! Ocorreu um erro no envio dos dados." }}
                            </div>
                            <div class="alert alert-info mt-4 mb-0" v-if="dataSentPartial == true" >
                                <i class="fas fa-exclamation-circle" ></i>
                                Alguns dos procedimentos selecionados podem ter sido finalizados com sucesso.
                                Estes serão removidos da lista acima.
                            </div>

                            <div class="alert alert-success mt-4 mb-0" v-if="dataSent == true" >
                                <i class="fas fa-check-circle" ></i>
                                Tudo certo!
                            </div>

                        </div>
                        <div class="card-footer" >
                            <div class="float-left" >
                                <button class="btn btn-info" v-on:click="refresh" >Voltar</button>
                            </div>
                            <div class="float-right" >
                                <button class="btn btn-success" v-on:click="submitData" >Finalizar</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </section>

</template>

<script>
    import axios from "axios";

    export default {
        data()
        {
            return {
                fase     :  0,
                login    : "",
                passw    : "",
                scpf     : null,
                dtFinal  : null,
                errorMsg : null,

                existingUser      : null,
                consultingUser    : false,

                existingPatient   : null,
                consultingPatient : false,

                textProc          : null,
                listProcs         : [],
                selectedProcs     : [],

                dataSent          : null,
                sendingData       : null,
                dataSentPartial   : null,
            };
        },

        props:
        {
            clinic: Object,
            edLogin: String,
            tokenCpf: String,
            url: { type: String, required: true },
        },

        methods:
        {
            start()
            {
                this.fase              = 0;
                this.login             = "";
                this.passw             = "";
                this.scpf              = null;
                this.errorMsg          = null;

                this.existingUser      = null;
                this.consultingUser    = false;

                this.existingPatient   = null;
                this.consultingPatient = false;
            },
            next()
            {
                this.fase += 1;
            },
            refresh()
            {
                this.fase              = 1;
                this.scpf              = null;
                this.errorMsg          = null;

                this.existingUser      = null;
                this.consultingUser    = false;

                this.existingPatient   = null;
                this.consultingPatient = false;

                this.dtFinal           = null;
                this.textProc          = null;
                this.listProcs         = [];
                this.selectedProcs     = [];

                this.dataSent          = null;
                this.sendingData       = null;
            },

            loginUser()
            {
                if ( this.login.length == 0 || this.passw.length == 0 )
                {
                    this.login          = "";
                    this.passw          = "";
                    this.existingUser   = null;
                    this.consultingUser = false;
                }
                else
                {
                    this.consultingUser = true;

                    axios
                    .post(
                        `${this.url}/easydental/login`, {
                        login: this.login,
                        passw: this.passw,
                        option: "procedimento",
                    } )
                    .then( response =>
                    {
                        let data = response.data.data;

                        this.$emit( "update:edLogin", data.user );

                        this.existingUser   = true;
                        this.consultingUser = false;
                        this.next();
                    } )
                    .catch( err =>
                    {
                        console.log( `login(), ${err.name}: ${err.message}` );

                        this.existingUser   = false;
                        this.consultingUser = false;
                    } );
                }
            },
            logoutUser()
            {
                axios
                .post( `${this.url}/easydental/logout` )
                .then( response =>
                {
                    this.existingUser   = false;
                    this.consultingUser = false;
                    this.$emit( "update:edLogin", "" );
                    this.start();
                } )
                .catch( err =>
                {
                    console.log( `logout(), ${err.name}: ${err.message}` );

                    this.existingUser   = false;
                    this.consultingUser = false;
                } );
            },

            consultPatient()
            {
                let cpf = this.scpf.replace(/\D/g, "");

                if ( this.scpf.length == 14 )
                {
                    this.consultingPatient = true;

                    axios
                    .post(
                        `${this.url}/clinica/procedimentos`,
                        {
                            scpf: cpf
                        }
                    )
                    .then( response =>
                    {
                        if ( response.data.data != null )
                        {
                            this.listProcs         = response.data.data;

                            this.listProcs.forEach( e =>
                            {
                                e.selected = false;
                            } );

                            if ( this.dtFinal == null )
                            {
                                let date     = this.today.split( "-" );
                                this.dtFinal = date.reverse().join( "/" );
                            }
                        }

                        this.existingPatient   = true;
                        this.consultingPatient = false;
                        this.next();
                    } )
                    .catch( err =>
                    {
                        console.log( `getProcedures(), ${err.name}: ${err.message}` );

                        this.existingPatient   = false;
                        this.consultingPatient = false;
                    } );
                }

                if ( this.scpf.length == 0 )
                {
                    this.existingPatient   = null;
                    this.consultingPatient = false;
                    this.scpf              = "";
                }
            },

            submitData()
            {
                this.dataSent        = null;
                this.sendingData     = true;
                this.dataSentPartial = null;

                if ( this.selectedProcs.length == 0 && this.textProc == null )
                {
                    this.errorMsg    = "Selecione um procedimento ou escreva alguma informação adicional.";
                    this.dataSent    = false;
                    this.sendingData = false;
                }
                else
                {
                    axios
                    .post(
                        `${this.url}/clinica/procedimentos/salvar`,
                        {
                            scpf       : this.scpf,
                            dtFinal    : this.dtFinal,
                            textProc   : this.textProc,
                            procedures : this.selectedProcs,
                        }
                    )
                    .then( response =>
                    {
                        this.dataSent    = true;
                        this.sendingData = false;

                        setTimeout( this.updateProcs, 2500 );
                    } )
                    .catch( err =>
                    {
                        console.log( `postProcedures(), ${err.name}: ${err.message}` );

                        this.dataSent    = false;
                        this.sendingData = false;

                        let data         = err.response.data;

                        if ( data && data.code && data.code == 4004 )
                        {
                            let procs            = data.data;
                            this.dataSentPartial = true;

                            this.listProcs.forEach( e =>
                            {
                                let el = procs.filter( proc => proc.idproc == e.idproc ).map( proc => proc );

                                if ( el.length > 0 )
                                    e.selected = false;
                            } );

                            setTimeout( this.updateProcs, 10000 );
                        }
                    } );
                }
            },

            updateProcs()
            {
                if ( this.selectedProcs.length > 0 )
                {
                    this.listProcs     = this.listProcs.filter( proc => !proc.selected ).map( proc => proc );
                    this.selectedProcs = [];
                }

                this.textProc        = null;
                this.dataSent        = null;
                this.dataSentPartial = null;
            },

            changeSelectedProcs()
            {
                this.selectedProcs = this.listProcs.filter( proc => proc.selected ).map( proc => proc );
            },

            removeError()
            {
                this.errorMsg = null;
                this.dataSent = null;
            },
        },

        mounted()
        {
            axios
            .post(
                `${this.url}/easy-log`,
                {
                    route : this.$route.path,
                    code  : 200,
                },
            );

            if ( this.edLogin )
            {
                this.next();

                if ( this.tokenCpf && this.tokenCpf.length > 0 )
                {
                    const decode = this.tokenCpf.split( "||" );
                    this.scpf    = decode[ 0 ].replace( /(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4" );

                    let date     = decode[ 1 ].split( "-" );
                    this.dtFinal = date.reverse().join( "/" );

                    this.consultPatient();
                }
            }
        },

        computed:
        {
            today()
            {
                return new Date().format();
            },
        }
    }
</script>


<style lang="scss" >
    #app .ed_custom .vuecal__event {
        background-color: #F4FBFC;
    }
    .vuecal__flex .vuecal__weekdays-headings {
        padding-right: 0 !important;
    }

    #details-modal .modal-body {
        padding-top: 0;
    }
    .fs-normal {
        font-weight: initial;
        cursor: pointer;
    }
</style>
