import Vue                from 'vue'
import Router             from 'vue-router'

import index              from './../components/pages/Index.vue';
import notFound           from './../components/pages/NotFound404.vue';

import cadastropaciente   from './../components/pages/Cadastro.vue';
import gerarlinkpaciente  from './../components/pages/GerarLinkPaciente.vue';

import documento          from './../components/pages/Documento.vue';
import gerarlinkdocumento from './../components/pages/GerarLinkDocumento.vue';

import procedimento       from './../components/pages/Procedimento.vue';
import finalizarProc      from './../components/pages/ProcedimentoFinalizar.vue';

import capturarimagem     from './../components/pages/CapturarImagem.vue';

import dashboard          from './../components/pages/Dashboard.vue';

import agenda             from './../components/pages/Agenda.vue';
import receitadigital     from './../components/pages/ReceitaDigital.vue';

// import estoque            from './../components/pages/Estoque.vue';

Vue.use(Router);

export default new Router({
    linkExactActiveClass: 'active',
    history: true,
    mode: 'history',
    routes: [
        {
            path:      '/',
            alias:     '/home',
            component: index,
            name:      'index',
        },

        {
            path:      '/cadastro',
            component: cadastropaciente,
            name:      'cadastropaciente',
        },{
            path:      '/gerar-link-paciente',
            component: gerarlinkpaciente,
            name:      'gerarlinkpaciente',
        },

        {
            path:      '/documento',
            component: documento,
            name:      'documento',
        },{
            path:      '/gerar-link-documento',
            component: gerarlinkdocumento,
            name:      'gerarlinkdocumento',
        },

        {
            path:      '/procedimentos-dia',
            component: procedimento,
            name:      'procedimentodia',
            props:     { today: true }
        },
        {
            path:      '/procedimentos-periodo',
            component: procedimento,
            name:      'procedimentoperiodo',
            props:     { today: false }
        },
        {
            path:      '/procedimentos-finalizar',
            component: finalizarProc,
            name:      'procedimentofinalizar',
            props:     { today: true }
        },

        {
            path:      '/capturar-imagem',
            component: capturarimagem,
            name:      'capturarimagem',
            props:     { today: false }
        },

        {
            path:      '/dashboard',
            component: dashboard,
            name:      'dashboard'
        },
        {
            path:      '/agenda',
            component: agenda,
            name:      'agenda'
        },
        {
            path:      '/receita-digital/:o?',
            component: receitadigital,
            name:      'receitadigital'
        },
        // {
        //     path:      '/estoque',
        //     component: estoque,
        //     name:      'estoque'
        // },


        {
            path:      '*',
            component: notFound,
            name:      'notfound'
        }
    ]
})
